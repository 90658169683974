import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  gift: 'gift',
  refresh: 'refresh',
  arrowNext: 'arrowNext',
  account: 'account',
  signOut: 'signOut',
  scanner: 'scanner',
  clearer: 'clearer',
  arrowCirclePrev: 'arrowCirclePrev',
  editCustomerInfo: 'editCustomerInfo',
  error: 'error',
  close: 'close',
  uploadPhoto: 'uploadPhoto',
  clearImage: 'clearImage',
  greenChecked: 'greenChecked',
  arrowDownBound: 'arrowDownBound',
  checked: 'checked',
  success: 'success',
  reject: 'reject',
};

export type IconName = keyof typeof iconList;

export type IconSize = '6' | '8' | '9' | '12' | '16' | '18' | '20' | '22' | '24' | '28' | '32' | '40' | '52';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
