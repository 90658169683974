import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Image, Input, Label, Loading, Typography
} from 'tfc-components';

import logo from 'assets/images/logo_lightmode.png';
import Container from 'components/organisms/Container';
import { setAccessToken, setRefreshToken } from 'services/common/storage';
import { loginService } from 'services/provinceLeader';
import { useAppDispatch } from 'store/hooks';
import { getProfileAsync } from 'store/provinceLeader';
import { ERROR_MESSAGES } from 'utils/constants';
import mapModifiers from 'utils/functions';
import useSchemas from 'utils/schemas';

export type LoginTypes = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { login } = useSchemas();
  const method = useForm<LoginTypes>({
    mode: 'onChange',
    resolver: yupResolver(login),
    defaultValues: {
      username: '',
      password: '',
    }
  });

  const { mutate: LoginMutate, isLoading } = useMutation(
    ['province-leader-login'],
    loginService,
    {
      onSuccess: (data) => {
        const { accessToken, refreshToken } = data;
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        dispatch(getProfileAsync()).unwrap().then(() => {
          navigate('/');
        });
      },
      onError: (err: any) => {
        if (err.length > 0) {
          method.setError('password', { message: ERROR_MESSAGES[err[0].message as keyof typeof ERROR_MESSAGES] || 'Thông tin đăng nhập không chính xác' });
        } else {
          method.setError('password', { message: 'Đã có lỗi xảy ra!' });
        }
      }
    }
  );

  const handleSubmit = (data: LoginTypes) => {
    LoginMutate(data);
  };
  return (
    <Container>
      <div className="p-login">
        <div className="p-login_logo">
          <Image
            alt="logo"
            ratio={1}
            imgSrc={logo}
          />
        </div>
        <div className="p-login_heading">
          <Typography.Heading
            type="h3"
            fontweight="600"
            extendClasses="fs-24 color-deepGreen"
            textStyle="center"
          >
            Đăng nhập
          </Typography.Heading>
        </div>
        <div className="p-login_subTitle">
          <Typography.Text
            textStyle="center"
          >
            Chào mừng trở lại, đăng nhập để tiếp tục.
          </Typography.Text>
        </div>
        <FormProvider {...method}>
          <form onSubmit={method.handleSubmit(handleSubmit)}>
            <Label
              spacingBottom={8}
              extendClasses="p-login_label"
            >
              Tài khoản
            </Label>
            <Controller
              name="username"
              render={({ field, fieldState: { error } }) => (
                <>
                  <Input
                    {...field}
                    extendClasses={mapModifiers('p-login_input', error && 'error')}
                    status={error ? 'error' : undefined}
                    bordered
                    placeholder="Nhập tên tài khoản"
                  />
                  {error && (
                    <div className="p-login_error">{error.message}</div>
                  )}
                </>
              )}
            />
            <div className="u-mt-20">
              <Label
                spacingBottom={8}
                extendClasses="p-login_label"
              >
                Mật khẩu
              </Label>
              <Controller
                name="password"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Input
                      type="password"
                      {...field}
                      extendClasses={mapModifiers('p-login_input', error && 'error')}
                      status={error ? 'error' : undefined}
                      bordered
                      placeholder="Nhập mật khẩu"
                    />
                    {error && (
                      <div className="p-login_error">{error.message}</div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="p-login_submit">
              <Button
                variant="primary"
                primaryColor="#015714"
                hoveredPrimaryColor="#186e2b"
                type="submit"
                loading={isLoading}
                loadingIndicator={<Loading.CircleDashed width={24} color="#fff" />}
              >
                <Typography.Text
                  textStyle="uppercase"
                  fontweight="600"
                  extendClasses="fs-14"
                >
                  ĐĂNG NHẬP
                </Typography.Text>
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Container>
  );
};

export default Login;
