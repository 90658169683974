import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Button, Typography } from 'tfc-components';

import NotifyModal from 'components/templates/NotifyModal';
import useInMobileDevice from 'hooks/useInMobileDevice';
import ocrCard from 'libs/cards';
import modifiers from 'utils/functions';

export const SvgScan: React.FC = () => (
  <div className="t-camera_svgScan">
    <svg viewBox="0 0 100 60">
      <mask id="mask">
        <rect fill="white" width="100%" height="100%" />
        <rect fill="black" width="88" height="48" x="6" y="6" />
      </mask>
      <path d="M 6 6 H 94 V 54 H 6 Z" fill="transparent" stroke="#b84144" />
      <rect mask="url(#mask)" fill="black" opacity={0.5} width="100%" height="100%" />
    </svg>
  </div>
);

interface CameraCaptureProps {
  name?: string;
  loading?: boolean;
  isError?: boolean;
  facingMode?: 'user' | 'environment';
  handleFinish?: (photos: File[]) => void;
  handleBack?: (photos?: File[]) => void;
  handleResetError?: () => void;
  handleScanSuccess?: (code: string) => void;
  notifySuccess?: {
    title: string;
    desc: string;
  }
}

const CameraCapture: React.FC<CameraCaptureProps> = ({
  name,
  loading,
  isError,
  facingMode,
  handleFinish,
  handleBack,
  handleResetError,
  handleScanSuccess,
  notifySuccess,
}) => {
  const codePattern = /[A-Z0-9]{6}/;

  //* Hooks
  const isMobile = useInMobileDevice();

  //* States
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [preview, setPreview] = useState('');
  const [photos, setPhotos] = useState<File[]>([]);
  const [errRequest, setErrRequest] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  const [success, setSuccess] = useState(false);

  //* Refs
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  //* Camera options
  const captureOption = useMemo(() => ({
    audio: false,
    video: {
      facingMode: facingMode ?? 'user',
      width: { ideal: 1920 },
      height: { ideal: 1080 },
      frameRate: { max: 30 },
      aspectRatio: 16 / 9,
    },
  }), [facingMode]);

  //* Functions
  const stopSelfie = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: false, video: true })
      .then((sm) => {
        if (!videoRef.current) return;
        videoRef.current.srcObject = sm;
        sm.getTracks().forEach((track) => {
          track.stop();
        });
      });
  };

  const handleCapture = async () => {
    if (videoRef.current && canvasRef.current) {
      const width = videoRef.current.videoWidth;
      const height = videoRef.current.videoHeight;

      const ctx = canvasRef.current.getContext('2d');
      const maxWidth = width > height ? 1280 : 720;
      const maxHeight = width > height ? 720 : 1280;

      let ratio = Math.min(maxWidth / width, maxHeight / height);
      if (ratio > 1) {
        ratio = 1;
      }

      const newWidth = width * ratio;
      const newHeight = height * ratio;
      canvasRef.current.width = newWidth;
      canvasRef.current.height = newHeight;
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, newWidth, newHeight);

        const imageDataURL = canvasRef.current.toDataURL('image/jpeg');
        setPreview(imageDataURL);
      }
      const code = await ocrCard(canvasRef.current);

      if (code) {
        const m = code.match(codePattern);

        if (m && handleScanSuccess) {
          handleScanSuccess(m[0]);
          setSuccess(true);
        } else {
          setError(true);
        }
      }
    }
  };

  const handleReset = () => {
    setPhotos([]);
    setIsOpen(false);
    setPreview('');
    setError(false);
    if (handleResetError) handleResetError();
  };

  const handleSubmit = async () => {
    if (handleFinish) handleFinish(photos);
  };

  const onBack = async () => {
    if (handleBack) {
      handleBack(photos);
    }
    await stopSelfie();
  };

  //* Effects
  useEffect(() => {
    setError(!!isError);
    if (isError) {
      setIsOpen(false);
    }
  }, [isError]);

  useEffect(() => {
    if (!errRequest) {
      const streamPromise = navigator.mediaDevices.getUserMedia(
        captureOption,
      ).catch((err: any) => {
        if (err.name === 'NotAllowedError') {
          setErrRequest(true);
        } else {
          setIsSupport(true);
        }
        return null;
      });
      if (videoRef) {
        streamPromise.then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        });
      }
      return () => {
        streamPromise.then((stream) => stream?.getTracks().forEach((track) => {
          track.stop();
        }));
      };
    }
    return () => { };
  }, [errRequest, captureOption]);

  return (
    <div className="t-camera">
      <div className="t-camera_wrapper">
        <div className="t-camera_camera">
          <video
            id={`video-${name}`}
            ref={videoRef}
            className={modifiers('t-camera_video', (isMobile && facingMode !== 'environment') && 'flip')}
            autoPlay
            playsInline
            controls={false}
            muted
          />
          <canvas
            id={`canvas-${name}`}
            ref={canvasRef}
          />
          <SvgScan />
          {preview && (
            <div className="t-camera_preview" style={{ backgroundImage: `url(${preview})` }} />
          )}
        </div>
      </div>
      <div className="u-mt-8">
        <Typography.Text
          textStyle="center"
          extendClasses="fs-14 color-raisinBlack"
        >
          Đặt toàn bộ thẻ cào vào khung hình.
        </Typography.Text>
      </div>
      <div className="u-mt-16">
        <Button
          type="button"
          variant="primary"
          extendClasses="t-camera_button"
          primaryColor="#015714"
          hoveredPrimaryColor="#186e2b"
          onClick={() => {
            if (success) {
              setPreview('');
              setSuccess(false);
            } else {
              handleCapture();
            }
          }}
        >
          <Typography.Text
            fontweight="600"
            extendClasses="fs-14"
          >
            {success ? 'THỬ LẠI' : 'NHẬN DIỆN MÃ BÍ MẬT'}
          </Typography.Text>
        </Button>
      </div>
      <NotifyModal
        isOpen={isOpen}
        title={notifySuccess?.title}
        desc={notifySuccess?.desc}
        submitText="Đồng ý"
        backText="Chụp lại"
        handleBack={handleReset}
        handleSubmit={handleSubmit}
        loadingSubmit={loading}
      />
      <NotifyModal
        isError
        isOpen={error}
        title="Thất bại"
        desc="Không nhận diện được mã.<br />Vui lòng thử lại!"
        submitText="Chụp lại"
        handleSubmit={handleReset}
      />
      <NotifyModal
        isOpen={errRequest}
        desc={`
            Chức năng này cần cho phép
            truy cập camera.
            \n
            Hướng dẫn bật truy cập camera:\n
            iOS: Vào cài đặt > Safari > Camera > Chọn Cho phép
            Android:`}
        submitText="Ok"
        handleSubmit={() => setErrRequest(false)}
      />
      <NotifyModal
        isOpen={isSupport}
        desc="Trình duyệt hoặc thiết bị của bạn không hỗ trợ camera để thực hiện tính năng này. Vui lòng kiểm tra lại."
        submitText="Ok"
        handleSubmit={() => {
          setIsSupport(false);
          onBack();
        }}
      />
    </div>
  );
};

export default CameraCapture;
