import React from 'react';
import { Controller } from 'react-hook-form';
import { useInfiniteQuery } from 'react-query';
import { Typography } from 'tfc-components';

import DatePicker from 'components/molecules/DatePicker';
import Pulldown from 'components/molecules/Pulldown';
import UploadPhoto from 'components/molecules/UploadPhoto';
import getListSaleService from 'services/sale';
import mapModifiers from 'utils/functions';

export interface DataDocumentDetailType {
  prize?: number,
  code: string,
  price: string,
  nameCustomer?: string,
  phone: string,
  address?: string,
  province?: {
    name: string;
    code: string;
  },
  sale?: {
    id?: number;
    name?: string;
  },
  time?: string,
  date?: string,
  timeVerifyCode?: string,
  dateVerifyCode?: string,
}
export interface DocumentDetailTypes {
  dataDocumentDetail?: DataDocumentDetailType
  readonly?: boolean
}

const DocumentDetailContent: React.FC<DocumentDetailTypes> = ({
  dataDocumentDetail,
  readonly,
}) => {
  const {
    data,
    fetchNextPage,
  } = useInfiniteQuery(
    ['getListSale', dataDocumentDetail?.province],
    async ({ pageParam = 1 }) => getListSaleService(
      { page: pageParam, limit: 15, provinceCode: dataDocumentDetail?.province?.code || '' }
    ),
    {
      getPreviousPageParam: (firstPage) => firstPage.meta.page - 1 ?? undefined,
      getNextPageParam: (lastPage) => lastPage.meta.page + 1 ?? undefined,
      enabled: !dataDocumentDetail?.sale && !!dataDocumentDetail?.province?.code && !readonly
    },
  );

  return (
    <div className="p-documentDetail">
      <div className={mapModifiers('p-documentDetail_wrapItem', readonly && 'isConfirmed')}>
        <div className="p-documentDetail_item">
          <Typography.Text extendClasses="p-documentDetail_label">
            Mã không
            được cào
          </Typography.Text>
          <Typography.Text
            fontweight="500"
            textStyle="right"
          >
            {dataDocumentDetail?.code}
          </Typography.Text>
        </div>
        <div className="p-documentDetail_item">
          <Typography.Text extendClasses="p-documentDetail_label">
            Loại quà
          </Typography.Text>
          <Typography.Text
            fontweight="500"
            textStyle="right"
          >
            {dataDocumentDetail?.price}
          </Typography.Text>
        </div>
        {
          dataDocumentDetail?.nameCustomer && (
            <div className="p-documentDetail_item">
              <Typography.Text extendClasses="p-documentDetail_label">
                Tên khách hàng
              </Typography.Text>
              <Typography.Text
                fontweight="500"
                textStyle="right"
              >
                {dataDocumentDetail.nameCustomer}
              </Typography.Text>
            </div>
          )
        }
        <div className="p-documentDetail_item">
          <Typography.Text extendClasses="p-documentDetail_label">
            Số điện thoại
          </Typography.Text>
          <Typography.Text
            fontweight="500"
            textStyle="right"
          >
            {dataDocumentDetail?.phone}
          </Typography.Text>
        </div>
        {
          dataDocumentDetail?.address && (
            <div className="p-documentDetail_item">
              <Typography.Text extendClasses="p-documentDetail_label">
                Địa chỉ
              </Typography.Text>
              <Typography.Text
                fontweight="500"
                textStyle="right"
              >
                {dataDocumentDetail.address}
              </Typography.Text>
            </div>
          )
        }
        {
          dataDocumentDetail?.province && (
            <div className="p-documentDetail_item">
              <Typography.Text extendClasses="p-documentDetail_label">
                Tỉnh/thành
              </Typography.Text>
              <Typography.Text
                fontweight="500"
                textStyle="right"
              >
                {dataDocumentDetail.province.name}
              </Typography.Text>
            </div>
          )
        }
        {/* <div className="p-documentDetail_item">
          <Typography.Text extendClasses="p-documentDetail_label">
            Tên Sale SUP
          </Typography.Text>
          <Typography.Text
            fontweight="500"
            textStyle="right"
          >
            {dataDocumentDetail?.sale?.name}
          </Typography.Text>
        </div> */}
        {
          dataDocumentDetail?.time && dataDocumentDetail.date && (
            <div className="p-documentDetail_item">
              <Typography.Text extendClasses="p-documentDetail_label">
                Thời điểm liên hệ
              </Typography.Text>
              <Typography.Text
                fontweight="500"
                textStyle="right"
              >
                {dataDocumentDetail.time}
                <br />
                {dataDocumentDetail.date}
              </Typography.Text>
            </div>
          )
        }
        {
          dataDocumentDetail?.timeVerifyCode && dataDocumentDetail.dateVerifyCode && (
            <div className="p-documentDetail_item">
              <Typography.Text extendClasses="p-documentDetail_label">
                Thời điểm xác thực mã
              </Typography.Text>
              <Typography.Text
                fontweight="500"
                textStyle="right"
              >
                {dataDocumentDetail.timeVerifyCode}
                <br />
                {dataDocumentDetail.dateVerifyCode}
              </Typography.Text>
            </div>
          )
        }
        <div className="p-documentDetail_item">
          <Typography.Text>
            Tên sale SUP
          </Typography.Text>
          <Controller
            name="saleId"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <Pulldown
                  isDisabled={!!dataDocumentDetail?.sale || readonly}
                  placeholder="Chọn tên sale SUP"
                  value={value}
                  optionsData={data ? data.pages.reduce((prev: OptionType[], curr) => (
                    [...prev, ...curr.data.map(((sale) => (
                      { label: sale.saleData.name, value: sale.saleData.id })))]), []) : []}
                  handleLoadMore={fetchNextPage}
                  handleChange={onChange}
                />
                {error && (
                  <div className="p-documentDetail_error">{(error as any).value.message}</div>
                )}
              </div>
            )}
          />
        </div>
        <div className="p-documentDetail_item">
          <Typography.Text>
            Ngày trao giải thực tế
          </Typography.Text>
          <Controller
            name="actualAwardDate"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <DatePicker
                  id="actualAwardDate"
                  placeholder="Chọn ngày trao giải"
                  disabled={readonly}
                  startDate={value}
                  maxDate={new Date()}
                  handleChangeDate={onChange}
                />
                {error && (
                  <div className="p-documentDetail_error">{(error as any).message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>

      <div className="p-documentDetail_form u-mt-40">
        <Typography.Text extendClasses="p-documentDetail_label">
          Hình CMND/CCCD
        </Typography.Text>
        <div className="p-documentDetail_form-wrapItem u-mt-8">
          <div className="p-documentDetail_form-item">
            <Controller
              name="beforeIdentityCard"
              render={({ field, fieldState: { error } }) => (
                <>
                  <UploadPhoto
                    readOnly={readonly}
                    description="Upload a photo"
                    title="Mặt trước"
                    handleChooseFile={field.onChange}
                    handleRemoveFile={() => field.onChange(null)}
                    value={field.value}
                    accepts=".png,.jpg,.gif,.jpeg"
                  />
                  {error && (
                    <div className="p-documentDetail_error">{(error as any).pathSubmit?.message}</div>
                  )}
                </>
              )}
            />
          </div>
          <div className="p-documentDetail_form-item">
            <Controller
              name="afterIdentityCard"
              render={({ field, fieldState: { error } }) => (
                <>
                  <UploadPhoto
                    description="Upload a photo"
                    title="Mặt sau"
                    handleChooseFile={field.onChange}
                    handleRemoveFile={() => field.onChange(null)}
                    accepts=".png,.jpg,.gif,.jpeg"
                    value={field.value}
                    readOnly={readonly}
                  />
                  {error && (
                    <div className="p-documentDetail_error">{(error as any).pathSubmit?.message}</div>
                  )}
                </>
              )}
            />
          </div>
        </div>
      </div>
      <div className="p-documentDetail_form u-mt-16">
        <Typography.Text extendClasses="p-documentDetail_label">
          Hình thẻ cào
        </Typography.Text>
        <div className="p-documentDetail_form-wrapItem u-mt-8">
          <div className="p-documentDetail_form-item">
            <Controller
              name="card"
              render={({ field, fieldState: { error } }) => (
                <>
                  <UploadPhoto
                    description="Upload a photo"
                    handleChooseFile={field.onChange}
                    value={field.value}
                    handleRemoveFile={() => field.onChange(null)}
                    accepts=".png,.jpg,.gif,.jpeg"
                    readOnly={readonly}
                  />
                  {error && (
                    <div className="p-documentDetail_error">{(error as any).pathSubmit?.message}</div>
                  )}
                </>
              )}
            />
          </div>
        </div>
      </div>
      <div className="p-documentDetail_form u-mt-16">
        <Typography.Text extendClasses="p-documentDetail_label">
          Biên bản trao giải
        </Typography.Text>
        <div className="p-documentDetail_form-wrapItem u-mt-8">
          <div className="p-documentDetail_form-item">
            <Controller
              name="awardMinutesOne"
              render={({ field, fieldState: { error } }) => (
                <>
                  <UploadPhoto
                    description="Upload a photo"
                    title="Biên bản trao giải"
                    handleChooseFile={field.onChange}
                    value={field.value}
                    handleRemoveFile={() => field.onChange(null)}
                    accepts=".png,.jpg,.gif,.jpeg"
                    readOnly={readonly}
                  />
                  {error && (
                    <div className="p-documentDetail_error">{(error as any).pathSubmit?.message}</div>
                  )}
                </>
              )}
            />
          </div>
          <div className="p-documentDetail_form-item">
            <Controller
              name="awardMinutesTwo"
              render={({ field, fieldState: { error } }) => (
                <>
                  <UploadPhoto
                    readOnly={readonly}
                    description="Upload a photo"
                    title="Biên bản giao nhận giải thưởng"
                    handleChooseFile={field.onChange}
                    value={field.value}
                    handleRemoveFile={() => field.onChange(null)}
                    accepts=".png,.jpg,.gif,.jpeg"
                  />
                  {error && (
                    <div className="p-documentDetail_error">{(error as any).pathSubmit?.message}</div>
                  )}
                </>
              )}
            />
          </div>
          <div className="p-documentDetail_form-item">
            <Controller
              name="awardMinutesThird"
              render={({ field, fieldState: { error } }) => (
                <>
                  <UploadPhoto
                    readOnly={readonly}
                    description="Upload a photo"
                    title="Biên bản cam kết"
                    handleChooseFile={field.onChange}
                    value={field.value}
                    handleRemoveFile={() => field.onChange(null)}
                    accepts=".png,.jpg,.gif,.jpeg"
                  />
                  {error && (
                    <div className="p-documentDetail_error">{(error as any).pathSubmit?.message}</div>
                  )}
                </>
              )}
            />
          </div>
          {dataDocumentDetail?.prize === 1 && (
            <div className="p-documentDetail_form-item">
              <Controller
                name="awardMinutesFourth"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <UploadPhoto
                      readOnly={readonly}
                      description="Upload a photo"
                      title="Tờ khai thuế thu nhập cá nhân"
                      handleChooseFile={field.onChange}
                      value={field.value}
                      handleRemoveFile={() => field.onChange(null)}
                      accepts=".png,.jpg,.gif,.jpeg"
                    />
                    {error && (
                      <div className="p-documentDetail_error">{(error as any).pathSubmit?.message}</div>
                    )}
                  </>
                )}
              />
            </div>
          )}
        </div>
      </div>
      <div className="p-documentDetail_form u-mt-16">
        <Typography.Text extendClasses="p-documentDetail_label">
          Hình trao giải
        </Typography.Text>
        <div className="p-documentDetail_form-wrapItem u-mt-8">
          <div className="p-documentDetail_form-item">
            <Controller
              name="awardPicture"
              render={({ field, fieldState: { error } }) => (
                <>
                  <UploadPhoto
                    description="Upload a photo"
                    handleChooseFile={field.onChange}
                    value={field.value}
                    handleRemoveFile={() => field.onChange(null)}
                    accepts=".png,.jpg,.gif,.jpeg"
                    readOnly={readonly}
                  />
                  {error && (
                    <div className="p-documentDetail_error">{(error as any).pathSubmit.message}</div>
                  )}
                </>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentDetailContent;
