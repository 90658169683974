/* eslint-disable @typescript-eslint/no-unused-vars */
import { FineUploaderBasic } from 'fine-uploader/lib/core';
import React, { useEffect, useState } from 'react';
import {
  Image, Input, Label, Typography
} from 'tfc-components';

import Icon from 'components/atoms/Icon';
import { getAccessToken } from 'services/common/storage';
import { URL_CONST } from 'utils/constants';

interface UploadPhotoProps {
  description: string,
  title?: string,
  handleChooseFile: (file: UploadFileType) => void;
  handleRemoveFile: () => void;
  accepts?: string;
  readOnly?: boolean;
  value?: UploadFileType;
}

const UploadPhoto: React.FC<UploadPhotoProps> = ({
  description,
  title,
  handleChooseFile,
  handleRemoveFile,
  accepts,
  readOnly,
  value
}) => {
  const token = getAccessToken();
  const [preview, setPreview] = useState('');

  const uploader = new FineUploaderBasic({
    autoUpload: false,
    request: {
      endpoint: URL_CONST.MEDIA_FILE_UPLOAD_CHUNK,
      customHeaders: {
        Authorization: `Bearer ${token}`,
      },
    },
    chunking: {
      enabled: true,
      mandatory: true,
      partSize: 1000000, // 1MB,
    },
    callbacks: {
      onTotalProgress(totalUploadedBytes: number, totalBytes: number) {
        // console.log({ totalUploadedBytes, totalBytes });
        // if (totalUploadedBytes === totalBytes) {
        //   setLoading(false);
        // }
      },
      onComplete(id: number, name: string, res: any, xhr: XMLHttpRequest) {
        const file = uploader.getFile(id);
        handleChooseFile({
          file, pathSubmit: res.path
        });
        // const uuid = uploader.getUuid(id);
        // const file = uploader.getFile(id);
        // setUuidImages((prev) => [...prev,
        // { chunkedUuid: uuid, fileExtension: file.name.split('.').pop() }]);

        // if (!res.success) {
        //   const errors = JSON.parse(xhr.response).errors as ValidateError[];
        // } else {
        // }
      },
      // onError: async (id: number, name: string, errorReason: string, xhr: XMLHttpRequest) => {
      //   if (xhr.status === 401) {
      //     await refreshTokenService()
      //       .then((data) => {
      //         setAccessToken(data.accessToken);
      //         setRefreshToken(data.refreshToken);
      //         uploader.setCustomHeaders({ Authorization: `Bearer ${data.accessToken}` });
      //         uploader.retry(id);
      //       });
      //   }
      // },
    }
  });
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      uploader.addFiles(file);
      uploader.uploadStoredFiles();
    }
  };

  useEffect(() => {
    if (value && value.file) {
      setPreview(typeof value.file === 'string' ? value.file : URL.createObjectURL(value.file));
    } else {
      setPreview('');
    }
  }, [value]);
  return (
    <div className="m-uploadPhoto">
      {
        preview ? (
          <div className="m-uploadPhoto_wrapImage">
            {
              !readOnly && (
                <div
                  onClick={() => {
                    handleRemoveFile();
                    setPreview('');
                  }}
                  className="m-uploadPhoto_icon"
                >
                  <Icon iconName="clearImage" size="24" />
                </div>
              )
            }
            <Image
              imgSrc={preview}
              ratio={100}
              alt="review"
              extendClasses="m-uploadPhoto_image"
            />
          </div>
        ) : (
          <Label className="m-uploadPhoto_wrapContent">
            <Icon iconName="uploadPhoto" size="24" />
            <div className="u-mt-8">
              <Typography.Text
                extendClasses="fs-12x20"
              >
                {description}
              </Typography.Text>
            </div>
            <div className="m-uploadPhoto_file">
              <Input disabled={readOnly} readOnly={readOnly} type="file" onChange={handleUpload} accept={accepts} />
            </div>
          </Label>
        )
      }
      <div className="m-uploadPhoto_title u-mt-8">
        <Typography.Text
          extendClasses="fs-12x20"
          textStyle="center"
        >
          {title}
        </Typography.Text>
      </div>
    </div>
  );
};

export default UploadPhoto;
