import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Typography } from 'tfc-components';

import DocumentDetailContent, { DataDocumentDetailType } from './DocumentDetail';
import PopupDocumentDetail from './DocumentDetailPopup';

import Icon from 'components/atoms/Icon';
import CustomModal from 'components/organisms/Modal';
import MainLayout from 'components/templates/MainLayout';
import { getPrizeExchangeDetailService, uploadDocuments } from 'services/prizes';
import { ROUTE_PATHS } from 'utils/constants';
import { formatDate, formatTimeHHMMSS } from 'utils/functions';
import useSchemas from 'utils/schemas';

type NotiTypes = {
  isOpen: boolean;
  message: string;
  status?: 'success' | 'error'
};

type FormTypes = {
  beforeIdentityCard: UploadFileType
  afterIdentityCard: UploadFileType
  card: UploadFileType
  awardMinutesOne: UploadFileType | null
  awardMinutesTwo: UploadFileType | null
  awardMinutesThird: UploadFileType | null
  awardMinutesFourth: UploadFileType | null
  awardPicture: UploadFileType
  saleId: OptionType
  actualAwardDate?: Date
};

// export interface DataDocumentDetailType {
//   code?: string,
//   price?: string,
//   nameCustomer?: string,
//   phone?: string,
//   address?: string,
//   province?: string,
//   nameSale?: string,
//   time?: string,
//   date?: string,
//   timeVerifyCode?: string,
//   dateVerifyCode?: string,
// }

export interface DocumentDetailTypes {
  dataDocumentDetail: DataDocumentDetailType
  readonly?: boolean
}

const DocumentDetail: React.FC = () => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const idParam = Number(searchParam.get('id'));
  const { documentDetail } = useSchemas();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [noti, setNoti] = useState<NotiTypes>({
    isOpen: false,
    status: undefined,
    message: ''
  });
  const mainlayout = document.getElementById('mainlayout');

  const { data: detailRes } = useQuery(
    ['prize-exchange-detail', idParam],
    () => getPrizeExchangeDetailService(idParam),
    {
      enabled: !!idParam
    }
  );

  const detailData: DataDocumentDetailType | undefined = useMemo(() => {
    if (detailRes) {
      const { prizeExchangeData, province, sale } = detailRes;
      return {
        prize: prizeExchangeData?.prize,
        code: '',
        price: prizeExchangeData?.prize === 1 ? '2 chỉ vàng' : '1,000,000 VND',
        nameCustomer: prizeExchangeData?.name,
        phone: prizeExchangeData?.phone || '',
        address: prizeExchangeData?.address,
        province: {
          name: province?.name || '',
          code: province?.code || ''
        },
        time: formatTimeHHMMSS(prizeExchangeData?.contactedAt),
        date: formatDate(prizeExchangeData?.contactedAt),
        timeVerifyCode: formatTimeHHMMSS(prizeExchangeData?.verifiedAt),
        dateVerifyCode: formatDate(prizeExchangeData?.verifiedAt),
        ...(sale && { sale })
      };
    }
    return undefined;
  }, [detailRes]);

  const method = useForm<FormTypes>({
    resolver: yupResolver<FormTypes>(documentDetail),
  });

  const { mutate: uploadMutate, isLoading } = useMutation(
    ['upload-documents'],
    uploadDocuments,
    {
      onSuccess: () => {
        setIsConfirmed(true);
        setIsOpenConfirm(false);
      },
      onError: () => {
        setNoti({
          isOpen: true,
          message: 'Đã có lỗi xảy ra!',
          status: 'error'
        });
      }
    }
  );

  const handleSubmit = () => {
    setIsOpenConfirm(true);
  };

  const handleConfirm = () => {
    const data = method.getValues();
    const {
      afterIdentityCard,
      awardMinutesOne,
      awardMinutesTwo,
      awardMinutesThird,
      awardMinutesFourth,
      awardPicture,
      beforeIdentityCard,
      card,
      saleId,
      actualAwardDate
    } = data;

    const uploadData = {
      frontIdCard: beforeIdentityCard?.pathSubmit!,
      backIdCard: afterIdentityCard?.pathSubmit!,
      scratchCard: card?.pathSubmit!,
      firstAwardReport: awardMinutesOne?.pathSubmit || null,
      secondAwardReport: awardMinutesTwo?.pathSubmit || null,
      thirdAwardReport: awardMinutesThird?.pathSubmit || null,
      fourthAwardReport: awardMinutesFourth?.pathSubmit || null,
      awardImage: awardPicture?.pathSubmit!,
      saleId: saleId.value as number,
      actualAwardDate: formatDate(actualAwardDate, 'YYYYMMDD')
    };
    uploadMutate({ id: idParam, data: uploadData });
  };

  const handleClose = () => {
    setIsOpenConfirm(false);
  };

  const onMethodSubmit = async (done: boolean) => {
    if (done) {
      navigate(ROUTE_PATHS.HOME);
    } else {
      const valid = await method.trigger();

      if (valid) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    if (!isOpenConfirm && mainlayout) {
      mainlayout.scrollTo(0, 0);
    }
  }, [isOpenConfirm, mainlayout]);

  useEffect(() => {
    method.reset({
      beforeIdentityCard: {
        file: detailRes?.prizeExchangeData?.frontIdCard?.url,
        pathSubmit: detailRes?.prizeExchangeData?.frontIdCard?.path,
      },
      afterIdentityCard: {
        file: detailRes?.prizeExchangeData?.backIdCard?.url,
        pathSubmit: detailRes?.prizeExchangeData?.backIdCard?.path,
      },
      card: {
        file: detailRes?.prizeExchangeData?.scratchCard?.url,
        pathSubmit: detailRes?.prizeExchangeData?.scratchCard?.path,
      },
      awardMinutesOne: {
        file: detailRes?.prizeExchangeData?.firstAwardReport?.url,
        pathSubmit: detailRes?.prizeExchangeData?.firstAwardReport?.path,
      },
      awardMinutesTwo: {
        file: detailRes?.prizeExchangeData?.secondAwardReport?.url,
        pathSubmit: detailRes?.prizeExchangeData?.secondAwardReport?.path,
      },
      awardMinutesThird: {
        file: detailRes?.prizeExchangeData?.thirdAwardReport?.url,
        pathSubmit: detailRes?.prizeExchangeData?.thirdAwardReport?.path,
      },
      awardMinutesFourth: {
        file: detailRes?.prizeExchangeData?.fourthAwardReport?.url,
        pathSubmit: detailRes?.prizeExchangeData?.fourthAwardReport?.path,
      },
      awardPicture: {
        file: detailRes?.prizeExchangeData?.awardImage?.url,
        pathSubmit: detailRes?.prizeExchangeData?.awardImage?.path,
      },
      actualAwardDate: detailRes?.prizeExchangeData?.actualAwardDate
        ? new Date(detailRes?.prizeExchangeData?.actualAwardDate) : undefined,
      ...(detailRes?.sale && {
        saleId: {
          label: detailRes?.sale?.name,
          value: detailRes?.sale?.id
        }
      })
    });
  }, [detailRes, method]);

  return (
    <div className="p-documentDetail">
      <MainLayout
        title={!isConfirmed ? `Chứng từ ${detailData?.nameCustomer}` : ''}
        footer={(
          <div className="p-documentDetail_wrapButton">
            {
              isConfirmed && (
                <Button
                  variant="primary"
                  primaryColor="#fff"
                  hoveredPrimaryColor="#fff"
                  disabled={!!detailRes?.prizeExchangeData?.verifiedAt}
                  onClick={() => navigate(`${ROUTE_PATHS.SCAN_CODE}?id=${idParam}`)}
                  loading={isLoading}
                  extendClasses="p-documentDetail_cancelButton"
                >
                  <Typography.Text
                    textStyle="uppercase"
                    fontweight="500"
                    extendClasses="fs-14 color-raisinBlack"
                  >
                    Xác thực mã
                  </Typography.Text>
                </Button>
              )
            }
            <Button
              variant="primary"
              primaryColor="#015714"
              hoveredPrimaryColor="#186e2b"
              onClick={() => onMethodSubmit(isConfirmed)}
              loading={isLoading}
            >
              <Typography.Text
                textStyle="uppercase"
                fontweight="500"
                extendClasses="fs-14x20"
              >
                {isConfirmed ? 'HOÀN TẤT' : ' GỬI CHỨNG TỪ'}
              </Typography.Text>
            </Button>
          </div>
        )}
      >
        <FormProvider {...method}>
          {
            isConfirmed && (
              <Typography.Heading
                extendClasses="color-deepGreen"
                textStyle="center"
                fontweight="500"
              >
                {`Chứng từ ${detailData?.nameCustomer}`}
              </Typography.Heading>
            )
          }
          <DocumentDetailContent dataDocumentDetail={detailData} readonly={isConfirmed} />
          <PopupDocumentDetail
            dataDocumentDetail={detailData}
            isOpen={isOpenConfirm}
            handleClose={handleClose}
            loading={isLoading}
            handleConfirm={handleConfirm}
          />
        </FormProvider>
      </MainLayout>
      <CustomModal
        variant="default"
        isOpen={noti.isOpen}
        handleClose={() => setNoti({ ...noti, isOpen: false })}
      >
        <div className="notiModal_wrapper">
          {noti.status === 'error'
            ? (
              <Icon iconName="error" size="32" />
            ) : (
              <Icon iconName="success" size="32" />
            )}
          <div className="notiModal_wrapper_message">
            <Typography.Heading textStyle="center">{noti.message}</Typography.Heading>
          </div>
          <div className="p-documentDetail_wrapButton">
            <Button
              variant="primary"
              primaryColor="#015714"
              hoveredPrimaryColor="#186e2b"
              onClick={() => {
                setNoti({ ...noti, isOpen: false });
                if (noti.status === 'success') {
                  navigate(`${ROUTE_PATHS.EXCHANGE_LIST}/${detailRes?.prizeExchangeData?.prize === 1 ? 'first' : 'second'}`);
                }
              }}
            >
              <Typography.Text
                textStyle="uppercase"
                fontweight="500"
                extendClasses="fs-14x20"
              >
                XÁC NHẬN
              </Typography.Text>
            </Button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default DocumentDetail;
