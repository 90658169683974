import React from 'react';
import ReactDatePicker from 'react-datepicker';

// import { IconName } from 'components/atoms/Icon';
import { datePickerLocalized } from './helper';

import mapModifiers from 'utils/functions';

interface DatePickerProps {
  startDate?: Date;
  handleChangeDate: (date?: Date) => void;
  id: string;
  disabled?: boolean;
  placeholder?: string;
  maxDate?: Date;
  minDate?: Date;
  locale?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  handleChangeDate, id, disabled, minDate, maxDate, startDate, placeholder, locale
}) => (
  <div
    className={mapModifiers(
      'm-datePicker'
    )}
  >
    <ReactDatePicker
      id={id}
      locale={datePickerLocalized(locale || 'vi')}
      className="m-datePicker_wrapper"
      wrapperClassName="m-datePicker_container"
      popperClassName="m-datePicker_popper"
      calendarClassName="m-datePicker_calendar"
      maxDate={maxDate}
      minDate={minDate}
      showPopperArrow={false}
      placeholderText={placeholder}
      disabled={disabled}
      selected={startDate}
      onChange={(date: Date) => handleChangeDate(date)}
      dateFormat="dd/MM/yyyy"
    />
  </div>
);

DatePicker.defaultProps = {
};

export default DatePicker;
