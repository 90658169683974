import {
  ExchangePrizeParamsTypes,
  PrizeExchangesParams,
  ProvinceDataTypes,
  ReportPrizeParamsTypes,
  RequestUploadDocumentsTypes,
  UpdatePrizeExchangeParams,
  VerifyPrizeParamsTypes
} from './types';

import axiosInstance from 'services/common/instance';
import { InlineResponse2008, PrizeExchangeResource } from 'services/models';

export const verifyPrizeCodeService = async (data: VerifyPrizeParamsTypes) => {
  const { code, id } = data;
  const res = await axiosInstance.post(`prize-exchanges/${id}/verify`, { code });
  return res.data;
};

export const getPrizeExchangesService = async (
  params: PrizeExchangesParams
): Promise<InlineResponse2008> => {
  const res = await axiosInstance.get('prize-exchanges', { params });
  return res.data;
};

export const getPrizeExchangeDetailService = async (id: number): Promise<PrizeExchangeResource> => {
  const res = await axiosInstance.get(`prize-exchanges/${id}`);
  return res.data.data;
};

export const updatePrizeExchangeService = async (
  params: UpdatePrizeExchangeParams
): Promise<void> => {
  const {
    id,
    name,
    phone,
    address
  } = params;
  await axiosInstance.put(`prize-exchanges/${id}/update-info`, { name, phone, address });
};

export const reportPrizeExchangeService = async (
  params: ReportPrizeParamsTypes
): Promise<void> => {
  const { id, reportedReason } = params;
  await axiosInstance.post(`prize-exchanges/${id}/report`, { reportedReason });
};

export const exchangePrizeService = async (
  params: ExchangePrizeParamsTypes
): Promise<void> => {
  const { id, code } = params;
  await axiosInstance.post(`prize-exchanges/${id}/exchange`, { code });
};

export const uploadDocuments = async (params: {
  id: number,
  data: RequestUploadDocumentsTypes
}) => {
  const { id, data } = params;

  await axiosInstance.post(`prize-exchanges/${id}/documents`, data);
};

export const getProvincesService = async (): Promise<ProvinceDataTypes[]> => {
  const res = await axiosInstance.get('provinces');
  return res.data.data;
};
