import React from 'react';
import { Button, Loading, Typography } from 'tfc-components';

import DocumentDetailContent, { DataDocumentDetailType } from './DocumentDetail';

import Icon from 'components/atoms/Icon';
import CustomModal, { ModalProps } from 'components/organisms/Modal';

interface ConfirmModalProps extends ModalProps {
  loading?: boolean;
  dataDocumentDetail?: DataDocumentDetailType,
  handleConfirm: () => void;
}

const PopupDocumentDetail: React.FC<ConfirmModalProps> = ({
  loading,
  isOpen,
  handleClose,
  handleConfirm,
  dataDocumentDetail
}) => (
  <CustomModal
    showIconClose
    isOpen={isOpen}
    variant="scanCode"
  >
    <div className="p-popupDocumentDetail_wrapper">
      <div className="p-popupDocumentDetail_confirmWrapper">
        <div className="p-popupDocumentDetail_checkedIcon">
          <Icon iconName="greenChecked" size="52" />
        </div>
        <div className="p-popupDocumentDetail_confirmText">
          <Typography.Text
            textStyle="center"
            extendClasses="color-deepGreen"
          >
            Xác nhận
          </Typography.Text>
        </div>
      </div>
      <div className="p-popupDocumentDetail_content">
        <DocumentDetailContent dataDocumentDetail={dataDocumentDetail} readonly />
      </div>
      <div className="p-popupDocumentDetail_buttonGroup">
        <Button
          variant="primary"
          primaryColor="#fff"
          extendClasses="p-popupDocumentDetail_cancelButton"
          hoveredPrimaryColor="#fff"
          onClick={handleClose}
          loading={loading}
          loadingIndicator={<Loading.CircleDashed width={24} color="#015714" />}
        >
          <Typography.Text
            fontweight="500"
            extendClasses="fs-14 color-raisinBlack"
          >
            HỦY BỎ
          </Typography.Text>
        </Button>
        <Button
          variant="primary"
          primaryColor="#015714"
          hoveredPrimaryColor="#186e2b"
          extendClasses="p-popupDocumentDetail_checkButton"
          onClick={handleConfirm}
          loading={loading}
          loadingIndicator={<Loading.CircleDashed width={24} color="#fff" />}
        >
          <Typography.Text
            fontweight="500"
            extendClasses="fs-14"
          >
            XÁC NHẬN
          </Typography.Text>
        </Button>
      </div>
    </div>
  </CustomModal>
);

export default PopupDocumentDetail;
