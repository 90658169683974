import DOMPurify from 'dompurify';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Typography } from 'tfc-components';

import { getPrizeExchangeDetailService } from 'services/prizes';
import { ROUTE_PATHS } from 'utils/constants';
import { formatDate, formatTimeHHMMSS } from 'utils/functions';

const SuccessfulScanning: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const code = searchParams.get('code');

  /* Queries */
  const { data: customerData } = useQuery('customerInfo', () => (id ? getPrizeExchangeDetailService(Number(id)) : undefined), {
    enabled: !!id,
  });

  const rowData = useMemo(() => ([
    {
      label: 'Mã không được cào',
      value: code,
    },
    {
      label: 'Loại quà',
      value: customerData?.prizeExchangeData?.prize === 1 ? '2 chỉ vàng' : '1,000,000 VND',
    },
    {
      label: 'Tên khách hàng',
      value: customerData?.prizeExchangeData?.name || '',
    },
    {
      label: 'Số điện thoại',
      value: customerData?.prizeExchangeData?.phone || '',
    },
    {
      label: 'Địa chỉ',
      value: customerData?.prizeExchangeData?.address || '',
    },
    {
      label: 'Tỉnh/thành',
      value: customerData?.province?.name || '',
    },
    {
      label: 'Tên Sale SUP',
      value: customerData?.sale?.name || '',
    },
    {
      label: 'Thời điểm xác thực mã',
      value: `${formatTimeHHMMSS(customerData?.prizeExchangeData?.verifiedAt)}<br />${formatDate(customerData?.prizeExchangeData?.verifiedAt)}`,
      isHtmlContent: true,
    },
  ]), [code, customerData]);
  const onUpdate = () => {
    navigate(`${ROUTE_PATHS.DOCUMENT_DETAIL}?id=${id}`);
  };

  const onCompleted = () => {
    navigate(ROUTE_PATHS.HOME);
  };

  return (
    <>
      <div className="p-successful">
        <Typography.Heading
          textStyle="center"
          fontweight="500"
          extendClasses="color-deepGreen"
        >
          Trao quà thành công
        </Typography.Heading>
        <div className="p-successful_info">
          {rowData.map((item, idx) => (
            <div key={`row-${idx.toString()}`} className="p-successful_row">
              <div className="p-successful_row_label">
                <Typography.Text>{item.label}</Typography.Text>
              </div>
              {item.isHtmlContent ? (
                <Typography.Text
                  type="div"
                  extendClasses="p-successful_row_value"
                  fontweight="500"
                  textStyle="right"
                >
                  <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.value || '') }} />
                </Typography.Text>
              ) : (
                <Typography.Text
                  extendClasses="p-successful_row_value"
                  fontweight="500"
                  textStyle="right"
                >
                  {item.value}
                </Typography.Text>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="p-successful_groupBtn">
        <div className="p-successful_groupBtn_wrapper">
          <Button
            variant="primary"
            primaryColor="#fff"
            extendClasses="p-scanCode_button"
            hoveredPrimaryColor="#fff"
            onClick={onUpdate}
          >
            <Typography.Text
              fontweight="600"
              extendClasses="fs-14 color-raisinBlack"
            >
              CẬP NHẬT CHỨNG TỪ
            </Typography.Text>
          </Button>
          <Button
            variant="primary"
            primaryColor="#015714"
            hoveredPrimaryColor="#186e2b"
            extendClasses="p-scanCode_button"
            onClick={onCompleted}
          >
            <Typography.Text
              fontweight="600"
              extendClasses="fs-14"
            >
              HOÀN TẤT
            </Typography.Text>
          </Button>
        </div>
      </div>
    </>
  );
};

export default SuccessfulScanning;
