/* eslint-disable import/prefer-default-export */
export const ERROR_MESSAGES = {
  'Entity Not Found': 'Mã cào không hợp lệ',
  'These credentials do not match our records.': 'Thông tin đăng nhập không chính xác'
};

export const ROUTE_PATHS = {
  HOME: '/',
  LOGIN: '/login',
  SCAN_CODE: '/scan-code',
  CUSTOMER_INFO: '/customer-info',
  EXCHANGE_LIST: '/exchange-list',
  SUCCESSFULL: '/successfull',
  DOCUMENT_LIST: '/document-list',
  REPORT: '/report',
  DOCUMENT_DETAIL: '/document-detail',
};

export const URL_CONST = {
  MEDIA_FILE_UPLOAD_CHUNK: 'api/v1/prize-exchanges/upload-chunk',
};
