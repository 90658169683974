import React from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Avatar, Button, Link, Typography
} from 'tfc-components';

import Icon from 'components/atoms/Icon';
import { logoutService } from 'services/provinceLeader';
import { useAppSelector } from 'store/hooks';
import { logout } from 'store/provinceLeader';
import { ROUTE_PATHS } from 'utils/constants';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useAppSelector((state) => state.provinceLeader.profile);
  const { mutate: logoutMutate } = useMutation(
    ['logout-service'],
    () => logoutService(),
    {
      onSuccess: () => {
        dispatch(logout());
        navigate(ROUTE_PATHS.LOGIN);
      }
    }
  );
  return (
    <div className="p-home">
      <div className="p-home_header">
        <div className="p-home_header_title">
          <Typography.Heading
            textStyle="uppercase"
            fontweight="600"
            extendClasses="color-deepGreen"
          >
            SALE LARUE
          </Typography.Heading>
        </div>
      </div>
      <div className="p-home_userCard">
        <div className="p-home_userCard_wrapCard">
          <div className="p-home_userCard_avatar">
            <Avatar
              shape="circle"
              extendClasses="p-home_userCard_avatar_inner"
              defaultAvatar={(
                <Icon iconName="account" size="28" />
              )}
            />
          </div>
          <div className="p-home_userCard_position">
            <Typography.Text fontweight="600" extendClasses="color-raisinBlack">Province leader</Typography.Text>
          </div>
          <div className="p-home_userCard_name">
            <Typography.Text extendClasses="color-raisinBlack">{profile?.provinceLeaderData?.name}</Typography.Text>
          </div>
          <table className="p-home_userCard_table">
            <thead>
              <tr>
                <th>Danh sách phát quà</th>
                {/* <th>Danh sách chứng từ</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Giải nhất:
                  {' '}
                  {profile?.totalFirstPrizeExchanges}
                </td>
                {/* <td>{profile?.totalPrizeExchangeDocuments}</td> */}
              </tr>
              <tr>
                <td>
                  Giải nhì:
                  {' '}
                  {profile?.totalSecondPrizeExchanges}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="p-home_extra">
        <div className="p-home_rewards">
          <div className="p-home_rewards_title">
            <Icon iconName="gift" size="24" />
            <Typography.Text fontweight="600">Phát quà</Typography.Text>
          </div>
          <Link
            extendClasses="p-home_rewards_item"
            href={`${ROUTE_PATHS.EXCHANGE_LIST}/first`}
          >
            <div className="p-home_rewards_wrapTitle">
              <span className="p-home_rewards_item_index">
                <span>1</span>
              </span>
              <span className="p-home_rewards_item_title">Phát quà giải nhất</span>
            </div>
            <Icon iconName="arrowNext" size="24" />
          </Link>
          <Link
            extendClasses="p-home_rewards_item"
            href={`${ROUTE_PATHS.EXCHANGE_LIST}/second`}
          >
            <div className="p-home_rewards_wrapTitle">
              <span className="p-home_rewards_item_index">
                <span>2</span>
              </span>
              <span className="p-home_rewards_item_title">Phát quà giải nhì</span>
            </div>
            <Icon iconName="arrowNext" size="24" />
          </Link>
        </div>
      </div>
      {
        profile && (
          <div className="p-home_logOut">
            <Button extendClasses="p-home_logOut_btn" contentDirection="ltr" onClick={() => logoutMutate()}>
              <Icon iconName="signOut" size="24" />
              <Typography.Text extendClasses="color-bostonRed" fontweight="600">
                LOG OUT
              </Typography.Text>
            </Button>
          </div>
        )
      }
    </div>
  );
};

export default Home;
