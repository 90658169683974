import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button, Input, Loading, Typography
} from 'tfc-components';

import Icon from 'components/atoms/Icon';
import MainLayout from 'components/templates/MainLayout';
import { getPrizeExchangeDetailService, updatePrizeExchangeService } from 'services/prizes';
import { ROUTE_PATHS } from 'utils/constants';
import mapModifiers, { formatDate, formatTimeHHMMSS } from 'utils/functions';
import useSchemas from 'utils/schemas';

interface FormTypes {
  name: string;
  phone: string;
  address: string;
}

const CustomerInfo: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { customerInfo } = useSchemas();
  const [editCustomer, setEditCustomer] = useState(false);
  const method = useForm<FormTypes>({
    mode: 'onChange',
    resolver: yupResolver(customerInfo),
    defaultValues: {
      name: '',
      phone: '',
      address: '',
    }
  });
  const { data: customerData } = useQuery('customerInfo', () => (id ? getPrizeExchangeDetailService(Number(id)) : undefined), {
    enabled: !!id,
    onSuccess(data) {
      method.reset({
        name: data?.prizeExchangeData?.name,
        phone: data?.prizeExchangeData?.phone,
        address: data?.prizeExchangeData?.address,
      });
    },
  });

  const { mutate, isLoading } = useMutation(
    'updateCustomerInfo',
    updatePrizeExchangeService,
    {
      onSuccess: () => {
        setEditCustomer(false);
      }
    }
  );

  const handleSubmit = (data: FormTypes) => {
    if (id) {
      mutate({ id: Number(id), ...data });
    }
  };
  if (!id) {
    navigate(-1);
  }
  return (
    <MainLayout
      title="Thông tin khách hàng"
      footer={(
        <div className={mapModifiers('p-customerInfo_wrapButton', !editCustomer && 'padding')}>
          {editCustomer ? (
            <div className="p-customerInfo_wrapButton_edit">
              <div className="p-customerInfo_wrapButton_submit">
                <Button
                  variant="default"
                  primaryColor="#015714"
                  hoveredPrimaryColor="#186e2b"
                  extendClasses="p-scanCode_button"
                  onClick={() => setEditCustomer(false)}
                  loading={isLoading}
                  loadingIndicator={<Loading.CircleDashed color="#015714" width={20} />}
                >
                  <Typography.Text
                    textStyle="uppercase"
                    fontweight="600"
                    extendClasses="fs-14"
                  >
                    HỦY BỎ
                  </Typography.Text>
                </Button>
              </div>
              <div className="p-customerInfo_wrapButton_submit">
                <Button
                  variant="primary"
                  primaryColor="#015714"
                  hoveredPrimaryColor="#186e2b"
                  extendClasses="p-scanCode_button"
                  onClick={method.handleSubmit(handleSubmit)}
                  type="submit"
                  loading={isLoading}
                  loadingIndicator={<Loading.CircleDashed color="#fff" width={20} />}

                >
                  <Typography.Text
                    textStyle="uppercase"
                    fontweight="600"
                    extendClasses="fs-14"
                  >
                    LƯU THAY ĐỔI
                  </Typography.Text>
                </Button>
              </div>
            </div>
          ) : (
            <div className="p-customerInfo_wrapButton_edit">
              <div className="p-customerInfo_wrapButton_submit">
                <Button
                  variant="primary"
                  primaryColor="#015714"
                  hoveredPrimaryColor="#186e2b"
                  extendClasses="p-scanCode_button"
                  onClick={() => navigate(`${ROUTE_PATHS.DOCUMENT_DETAIL}?id=${customerData?.prizeExchangeData?.id}`)}
                >
                  <Typography.Text
                    textStyle="uppercase"
                    fontweight="600"
                    extendClasses="fs-12x20"
                  >
                    CẬP NHẬT CHỨNG TỪ
                  </Typography.Text>
                </Button>
              </div>
              <div className="p-customerInfo_wrapButton_submit">
                <Button
                  variant="primary"
                  primaryColor="#015714"
                  hoveredPrimaryColor="#186e2b"
                  disabled={!!customerData?.prizeExchangeData?.verifiedAt}
                  extendClasses="p-scanCode_button"
                  onClick={() => navigate(`${ROUTE_PATHS.SCAN_CODE}?id=${customerData?.prizeExchangeData?.id}`)}
                >
                  <Typography.Text
                    textStyle="uppercase"
                    fontweight="600"
                    extendClasses="fs-12x20"
                  >
                    XÁC THỰC MÃ
                  </Typography.Text>
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    >
      <FormProvider {...method}>
        <div className="p-customerInfo">
          <div className="p-customerInfo_wrapContent">
            {
              !editCustomer && (
                <div className="p-customerInfo_icon" onClick={() => setEditCustomer(true)}>
                  <Icon size="24" iconName="editCustomerInfo" />
                </div>
              )
            }
            <div className="p-customerInfo_wrapItem">
              <div className="p-customerInfo_item">
                <Typography.Text>
                  Loại quà
                </Typography.Text>
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-14 color-raisinBlack"
                  textStyle="right"
                >
                  {customerData?.prizeExchangeData?.prize === 1 ? '2 chỉ vàng' : '1,000,000 VND'}
                </Typography.Text>
              </div>
              <div className="p-customerInfo_item">
                <Typography.Text extendClasses="p-customerInfo_label">
                  Tên khách hàng
                </Typography.Text>
                {
                  editCustomer ? (
                    <Controller
                      name="name"
                      render={({ field, fieldState: { error } }) => (
                        <div style={{ width: '100%' }}>
                          <Input
                            {...field}
                            extendClasses={mapModifiers('p-scanCode_input', error ? 'error' : '')}
                            bordered
                            placeholder="Nhập tên KH"
                          />
                          {error && (
                            <div className="p-scanCode_error">{error.message}</div>
                          )}
                        </div>
                      )}
                    />
                  ) : (
                    <Typography.Text
                      fontweight="600"
                      extendClasses="fs-14 color-raisinBlack"
                      textStyle="right"
                    >
                      {method.getValues('name')}
                    </Typography.Text>
                  )
                }
              </div>
              <div className="p-customerInfo_item">
                <Typography.Text extendClasses="p-customerInfo_label">
                  Số điện thoại
                </Typography.Text>
                {
                  editCustomer ? (
                    <Controller
                      name="phone"
                      render={({ field, fieldState: { error } }) => (
                        <div style={{ width: '100%' }}>
                          <Input
                            type="number"
                            {...field}
                            extendClasses={mapModifiers('p-scanCode_input', error ? 'error' : '')}
                            bordered
                            placeholder="Nhập số điện thoại"
                          />
                          {error && (
                            <div className="p-scanCode_error">{error.message}</div>
                          )}
                        </div>
                      )}
                    />
                  ) : (
                    <Typography.Text
                      fontweight="600"
                      extendClasses="fs-14 color-raisinBlack"
                      textStyle="right"
                    >
                      {method.getValues('phone')}
                    </Typography.Text>
                  )
                }
              </div>
              <div className="p-customerInfo_item">
                <Typography.Text extendClasses="p-customerInfo_label">
                  Địa chỉ
                </Typography.Text>
                {
                  editCustomer ? (
                    <Controller
                      name="address"
                      render={({ field, fieldState: { error } }) => (
                        <div style={{ width: '100%' }}>
                          <Input
                            {...field}
                            extendClasses={mapModifiers('p-scanCode_input', error ? 'error' : '')}
                            bordered
                            placeholder="Nhập địa chỉ"
                          />
                          {error && (
                            <div className="p-scanCode_error">{error.message}</div>
                          )}
                        </div>
                      )}
                    />
                  ) : (
                    <Typography.Text
                      fontweight="600"
                      extendClasses="fs-14 color-raisinBlack"
                      textStyle="right"
                    >
                      {method.getValues('address')}
                    </Typography.Text>
                  )
                }
              </div>
              <div className="p-customerInfo_item">
                <Typography.Text extendClasses="p-customerInfo_label">
                  Tỉnh/thành
                </Typography.Text>
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-14 color-raisinBlack"
                  textStyle="right"
                >
                  {customerData?.province?.name}
                </Typography.Text>
              </div>
              <div className="p-customerInfo_item">
                <Typography.Text>
                  Thời điểm liên hệ
                </Typography.Text>
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-14 color-raisinBlack"
                  textStyle="right"
                >
                  {formatTimeHHMMSS(customerData?.prizeExchangeData?.contactedAt)}
                  <br />
                  {formatDate(customerData?.prizeExchangeData?.contactedAt)}
                </Typography.Text>
              </div>
              <div className="p-customerInfo_item">
                <Typography.Text>
                  Thời điểm báo sale
                </Typography.Text>
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-14 color-raisinBlack"
                  textStyle="right"
                >
                  {formatTimeHHMMSS(customerData?.prizeExchangeData?.exportedAt)}
                  <br />
                  {formatDate(customerData?.prizeExchangeData?.exportedAt)}
                </Typography.Text>
              </div>
              <div className="p-customerInfo_item">
                <Typography.Text>
                  Báo cáo lúc
                </Typography.Text>
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-14 color-raisinBlack"
                  textStyle="right"
                >
                  {formatTimeHHMMSS(customerData?.prizeExchangeData?.reportedAt)}
                  <br />
                  {formatDate(customerData?.prizeExchangeData?.reportedAt)}
                </Typography.Text>
              </div>
              <div className="p-customerInfo_item">
                <Typography.Text>
                  Lý do báo cáo
                </Typography.Text>
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-14 color-raisinBlack"
                  textStyle="right"
                >
                  {customerData?.prizeExchangeData?.reportedReason}
                </Typography.Text>
              </div>
              <div className="p-customerInfo_item">
                <Typography.Text>
                  Cập nhật chứng từ lúc
                </Typography.Text>
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-14 color-raisinBlack"
                  textStyle="right"
                >
                  {formatTimeHHMMSS(customerData?.prizeExchangeData?.documentAddedAt)}
                  <br />
                  {formatDate(customerData?.prizeExchangeData?.documentAddedAt)}
                </Typography.Text>
              </div>
              <div className="p-customerInfo_item">
                <Typography.Text>
                  Xác thực mã lúc
                </Typography.Text>
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-14 color-raisinBlack"
                  textStyle="right"
                >
                  {formatTimeHHMMSS(customerData?.prizeExchangeData?.verifiedAt)}
                  <br />
                  {formatDate(customerData?.prizeExchangeData?.verifiedAt)}
                </Typography.Text>
              </div>
              {
                customerData?.prizeExchangeData?.documentRejectedAt && (
                  <div className="p-customerInfo_item">
                    <Typography.Text>
                      Chứng từ bị từ chối lúc
                    </Typography.Text>
                    <Typography.Text
                      fontweight="600"
                      extendClasses="fs-14 color-raisinBlack"
                      textStyle="right"
                    >
                      {formatTimeHHMMSS(customerData?.prizeExchangeData?.documentRejectedAt)}
                      <br />
                      {formatDate(customerData?.prizeExchangeData?.documentRejectedAt)}
                    </Typography.Text>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </FormProvider>
    </MainLayout>
  );
};

export default CustomerInfo;
