import axiosInstance from 'services/common/instance';
import { InlineResponse201Data, LoginRequest, ProvinceLeaderResource } from 'services/models';

export const loginService = async (data: LoginRequest): Promise<InlineResponse201Data> => {
  const res = await axiosInstance.post('province-leaders/login', data);
  return res.data;
};

export const logoutService = async (): Promise<void> => {
  await axiosInstance.post('province-leaders/logout');
};

export const getProfileService = async (): Promise<ProvinceLeaderResource> => {
  const res = await axiosInstance.get('province-leaders/profile');
  return res.data.data;
};
