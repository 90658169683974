/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button, Input, Loading, Typography
} from 'tfc-components';

import ConfirmModal from './ConfirmModal';

import Icon from 'components/atoms/Icon';
import CameraCapture from 'components/templates/CameraCapture';
import MainLayout from 'components/templates/MainLayout';
import { getPrizeExchangeDetailService, verifyPrizeCodeService } from 'services/prizes';
import { VerifyPrizeParamsTypes } from 'services/prizes/types';
import { ROUTE_PATHS } from 'utils/constants';
import mapModifiers from 'utils/functions';
import useSchemas from 'utils/schemas';

export type ScanCodeFormType = {
  scanner: string;
};

const ScanCode: React.FC = () => {
  //* Hooks
  const navigate = useNavigate();
  const { scanner } = useSchemas();

  //* States
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [openScanCamera, setOpenScanCamera] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  //* Refs

  //* React-hook-form
  const method = useForm<ScanCodeFormType>({
    resolver: yupResolver(scanner),
    defaultValues: {
      scanner: '',
    },
  });

  //* Queries
  const { data: customerData } = useQuery('customerInfo', () => (id ? getPrizeExchangeDetailService(Number(id)) : undefined), {
    enabled: !!id,
  });
  const { mutate: verifyCodeMutate, isLoading: loadingVerify } = useMutation(
    ['verify-code-service'],
    verifyPrizeCodeService,
    {
      onSuccess: () => {
        setIsOpen(true);
      },
      onError(error: any) {
        if (error.response.status === 404) {
          method.setError('scanner', {
            message: 'Mã thẻ cào không hợp lệ.',
            type: 'api-error',
          });
        } else {
          method.setError('scanner', {
            message: 'Đã có lỗi xảy ra!',
            type: 'api-error',
          });
        }
      },
    }
  );

  //* Functions
  const handleSubmit = (data: ScanCodeFormType) => {
    const reqData: VerifyPrizeParamsTypes = {
      code: data.scanner,
      id: Number(id)
    };
    verifyCodeMutate(reqData);
  };

  const handleClear = () => {
    method.reset();
  };

  const handleCapturePhotos = (photos: File[]) => {
    console.log(photos);
  };

  const handleCheck = () => {
    navigate(`${ROUTE_PATHS.SUCCESSFULL}?id=${id}&code=${method.getValues().scanner}`);
  };

  //* Memos
  const formApiError = useMemo(
    () => !!Object.entries(method.formState.errors).find(
      ([, value]) => value.type === 'api-error'
    ),
    [method.formState]
  );

  if (!id) {
    navigate(-1);
  }

  return (
    <MainLayout
      title="Phát quà"
      footer={(
        <div className="p-scanCode_wrapSubmit">
          {formApiError && (
            <div className="p-scanCode_wrapButton">
              <Button
                variant="primary"
                primaryColor="#fff"
                extendClasses="p-scanCode_button"
                hoveredPrimaryColor="#fff"
                onClick={() => navigate(`${ROUTE_PATHS.REPORT}?id=${id}`)}
              >
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-14 color-raisinBlack"
                >
                  BÁO CÁO
                </Typography.Text>
              </Button>
            </div>
          )}
          <div
            className={mapModifiers(
              'p-scanCode_wrapButton',
              !formApiError && 'fullWidth'
            )}
          >
            <Button
              variant="primary"
              primaryColor="#015714"
              hoveredPrimaryColor="#186e2b"
              extendClasses="p-scanCode_button"
              loading={loadingVerify}
              loadingIndicator={<Loading.CircleDashed width={24} color="#fff" />}
              onClick={method.handleSubmit(handleSubmit)}
            >
              <Typography.Text
                fontweight="600"
                extendClasses="fs-14"
              >
                KIỂM TRA
              </Typography.Text>
            </Button>
          </div>
        </div>
      )}
    >
      <div className="p-scanCode">
        <FormProvider {...method}>
          <form
            className="p-scanCode_form"
          >
            <div className="u-mt-8">
              <div className="u-mb-8">
                <Typography.Text
                  extendClasses="p-scanCode_label"
                  textStyle="center"
                >
                  Nhập mã không được cào của khách hàng
                </Typography.Text>
              </div>
              <Controller
                name="scanner"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Input
                      type="scanner"
                      {...field}
                      extendClasses={mapModifiers(
                        'p-scanCode_input',
                        error && 'error'
                      )}
                      status={error ? 'error' : undefined}
                      bordered
                      placeholder="Nhập mã không được cào"
                      inputSurfix={(
                        <div className="p-scanCode_wrapIcon">
                          {error && (
                            <div
                              onClick={handleClear}
                              className="p-scanCode_icon clear"
                            >
                              <Icon iconName="clearer" size="24" />
                            </div>
                          )}
                          <div
                            className="p-scanCode_icon scan"
                            onClick={() => setOpenScanCamera(!openScanCamera)}
                          >
                            <Icon iconName="scanner" size="24" />
                          </div>
                        </div>
                      )}
                    />
                    {error && (
                      <div className="p-scanCode_error">{error.message}</div>
                    )}
                  </>
                )}
              />
              {openScanCamera && (
                <CameraCapture
                  facingMode="environment"
                  handleFinish={handleCapturePhotos}
                  handleScanSuccess={(code) => {
                    method.clearErrors();
                    method.setValue('scanner', code);
                  }}
                  name="scanner"
                />
              )}
            </div>
          </form>
        </FormProvider>
      </div>
      <ConfirmModal
        listData={{
          id: Number(id),
          giftCode: method.getValues().scanner,
          giftType: customerData?.prizeExchangeData?.prize || 2,
          name: customerData?.prizeExchangeData?.name || '',
          phone: customerData?.prizeExchangeData?.phone || '',
          province: customerData?.province?.code || '',
        }}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        handleCheck={handleCheck}
      />
    </MainLayout>
  );
};
export default ScanCode;
