import { SaleParamsType } from './types';

import axiosInstance from 'services/common/instance';
import { InlineResponse20011 } from 'services/models';

const getListSaleService = async (params: SaleParamsType): Promise<InlineResponse20011> => {
  const response = await axiosInstance.get('sales', { params });
  return response.data;
};

export default getListSaleService;
