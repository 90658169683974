import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'tfc-components';

import Icon from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

interface HeaderProps {
  title?: string;
  allowGoBack?: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, allowGoBack }) => {
  const navigate = useNavigate();

  return (
    <header className={mapModifiers('o-header', allowGoBack && 'allowGoBack')}>
      <div className="o-header_prev" role="button" onClick={() => navigate(-1)}>
        <Icon iconName="arrowCirclePrev" size="28" />
      </div>
      <Typography.Text
        fontweight="500"
        textStyle="center"
        extendClasses="o-header_title color-deepGreen"
      >
        {title}
      </Typography.Text>
    </header>
  );
};

export default Header;
