import React from 'react';

import Container from 'components/organisms/Container';
import Header from 'components/organisms/Header';
import useWindowSize from 'hooks/useWindowSize';
import mapModifiers from 'utils/functions';

interface MainLayoutProps {
  title?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  allowGoBack?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  title, allowGoBack, children, footer
}) => {
  const { height } = useWindowSize();
  return (
    <div className={mapModifiers('t-mainLayout', !!footer && 'hasFooter')}>
      {
        (title || allowGoBack) && (
          <Header allowGoBack={allowGoBack} title={title} />
        )
      }
      <main className="t-mainLayout_main" id="mainlayout" style={{ height: `${height}px` }}>
        <Container>
          {children}
        </Container>
      </main>
      {footer && (
        <footer className="t-mainLayout_footer">
          <Container>
            {footer}
          </Container>
        </footer>
      )}
    </div>
  );
};

export default MainLayout;
