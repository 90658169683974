const useInMobileDevice = () => {
  const isInApp = (ua: string) => {
    const rules = [
      '(iPhone|iPod|iPad)',
      'Android.*',
    ];
    const regex = new RegExp(`(${rules.join('|')})`, 'ig');
    return !!ua.match(regex);
  };

  return isInApp(navigator.userAgent);
};

export default useInMobileDevice;
