import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';

import Pulldown from 'components/molecules/Pulldown';
import PrizeItem from 'components/organisms/PrizeItem';
import MainLayout from 'components/templates/MainLayout';
import { getPrizeExchangesService } from 'services/prizes';
import { useAppSelector } from 'store/hooks';
import { ROUTE_PATHS } from 'utils/constants';

const DocumentList: React.FC = () => {
  const profile = useAppSelector((state) => state.provinceLeader.profile);
  const { ref, inView } = useInView();
  const [prizeType, setPrizeType] = useState<number | undefined>();

  const {
    data,
    fetchNextPage,
  } = useInfiniteQuery(
    ['getPrizeExchanges', prizeType],
    async ({ pageParam = 1 }) => getPrizeExchangesService({
      prize: prizeType, page: pageParam, limit: 15
    }),
    {
      enabled: !!profile,
      getPreviousPageParam: (firstPage) => firstPage.meta.page - 1 ?? undefined,
      getNextPageParam: (lastPage) => lastPage.meta.page + 1 ?? undefined,
    },
  );
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);
  return (
    <MainLayout title="Danh sách chứng từ">
      <Pulldown
        placeholder="Loại giải"
        optionsData={[
          {
            label: 'Giải 1',
            value: 1
          },
          {
            label: 'Giải 2',
            value: 2
          }
        ]}
        handleChange={(option) => setPrizeType(option.value)}
      />
      <div className="p-documentList">
        {data?.pages.map((page) => (
          <React.Fragment key={page.meta.page + 1}>
            {page.data.map((prizeItem, idx) => (
              <div
                key={`exchangeList${idx.toString()}`}
                className="p-exchangeList"
              >
                <PrizeItem
                  name={prizeItem.prizeExchangeData?.name}
                  phone={prizeItem.prizeExchangeData?.phone}
                  slug={`${ROUTE_PATHS.DOCUMENT_DETAIL}?id=${prizeItem.prizeExchangeData?.id}`}
                />
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
      <div className="p-documentList__loadMore" ref={ref} />
    </MainLayout>
  );
};

export default DocumentList;
