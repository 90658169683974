/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Select, { Props as SelectProps, components } from 'react-select';
import { Loading } from 'tfc-components';

import Icon from 'components/atoms/Icon';
import useScrollInfinite from 'hooks/useScrollInfinite';

interface PulldownProps extends SelectProps {
  optionsData: OptionType[];
  value?: OptionType;
  placeholder?: string;
  error?: string;
  handleChange?(option: OptionType): void;
  hasNextPage?: boolean;
  handleLoadMore?: () => void;
}

const CaretDownIcon = ({ menuIsOpen }: any) => (
  <span
    style={{
      transform: menuIsOpen && 'rotate(180deg)',
      transition: 'all .3s ease',
    }}
  >
    <Icon iconName="arrowDownBound" size="9" />
  </span>
);

const DropdownIndicator: React.FC = (props: any) => {
  const { selectProps } = props;
  const { menuIsOpen } = selectProps;
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon menuIsOpen={menuIsOpen} />
    </components.DropdownIndicator>
  );
};

const MenuList: React.FC = (props: any) => {
  const { children, selectProps } = props;
  const { onHandleLoadMore, hasNextPage } = selectProps;
  const { setNode } = useScrollInfinite(() => {
    if (onHandleLoadMore) onHandleLoadMore();
  });
  return (
    <components.MenuList {...props}>
      {children}
      {
        hasNextPage && (
          <div className="m-pulldown_node" ref={(node) => setNode(node)}>
            <Loading.Ripple width={40} />
          </div>
        )
      }
    </components.MenuList>
  );
};

const Pulldown: React.FC<PulldownProps> = ({
  optionsData, value, placeholder, handleChange,
  error, ...props
}) => (
  <div className="m-pulldown">
    <Select
      {...props}
      className={`m-pulldown_select ${props.className}`}
      classNamePrefix="m-pulldown_select"
      options={optionsData}
      placeholder={placeholder}
      value={value}
      onChange={(v: any) => handleChange && handleChange(v)}
      components={{
        MenuList,
        DropdownIndicator,
        IndicatorSeparator: () => null
      }}
      styles={{
        control: (base: any) => ({
          ...base,
          color: '#000',
          fontSize: 14,
          boxShadow: 'none',
          borderRadius: '6px',
          borderColor: error ? '#E0001A' : '#222',
          '&:hover': {
            borderColor: '#222',
          },
          cursor: 'pointer',
          height: '40px',
        }),
        valueContainer: (base: any) => ({
          ...base,
          paddingLeft: '12px',
          paddingRight: '12px',
        }),
        dropdownIndicator: (provided: any) => ({
          ...provided,
          transition: 'all .3s ease',
          padding: '8px',
        }),
      }}
      // @ts-ignore
      hasNextPage={props.hasNextPage}
      onHandleLoadMore={props.handleLoadMore}
    />
  </div>
);

export default Pulldown;
