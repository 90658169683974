import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button, Loading, TextArea, Typography
} from 'tfc-components';

import MainLayout from 'components/templates/MainLayout';
import { reportPrizeExchangeService } from 'services/prizes';
import { ROUTE_PATHS } from 'utils/constants';
import mapModifiers from 'utils/functions';
import useSchemas from 'utils/schemas';

type ReportPrizeFormDate = {
  reportedReason: string;
};

const PageReport: React.FC = () => {
  const navigate = useNavigate();
  const { reportPrize } = useSchemas();
  const method = useForm<ReportPrizeFormDate>({
    resolver: yupResolver(reportPrize),
    defaultValues: {
      reportedReason: '',
    },
  });
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  /* States */
  const [success, setSuccess] = useState(false);

  /* Queries */
  const { mutate: reportMutate, isLoading } = useMutation(
    ['report-prize'],
    reportPrizeExchangeService,
    {
      onSuccess: () => {
        setSuccess(true);
      },
      onError: () => {
        method.setError('reportedReason', {
          message: 'Đã có lỗi xảy ra!',
          type: 'api-error',
        });
      },
    }
  );

  const onSubmit = async () => {
    const isValid = await method.trigger();

    if (isValid) {
      const { reportedReason } = method.getValues();
      reportMutate({
        id: Number(id),
        reportedReason,
      });
    }
  };

  return (
    <MainLayout
      allowGoBack
      footer={(
        <div className="p-pageReport_wrapButton">
          <Button
            variant="primary"
            primaryColor="#015714"
            hoveredPrimaryColor="#186e2b"
            extendClasses="p-scanCode_button"
            loading={isLoading}
            loadingIndicator={<Loading.CircleDashed width={24} color="#fff" />}
            onClick={() => {
              if (success) {
                navigate(ROUTE_PATHS.HOME);
              } else {
                onSubmit();
              }
            }}
          >
            <Typography.Text
              textStyle="uppercase"
              fontweight="600"
              extendClasses="fs-14x20"
            >
              {success ? 'QUAY LẠI TRANG CHỦ' : 'BÁO CÁO'}
            </Typography.Text>
          </Button>
        </div>
      )}
    >
      <div className="p-pageReport">
        {success ? (
          <div className="p-pageReport_wrapContent">
            <Typography.Heading
              extendClasses="color-deepGreen"
              textStyle="center"
              fontweight="500"
            >
              Hệ thống đã tiếp nhận báo cáo!
            </Typography.Heading>
            <div className="u-mt-40">
              <Typography.Text
                textStyle="center"
                extendClasses="fs-16x24"
              >
                Lý do:
                {' '}
                {method.getValues().reportedReason}
              </Typography.Text>
            </div>
          </div>
        ) : (
          <FormProvider {...method}>
            <form
              className="p-scanCode_form"
            >
              <div className="u-mt-8">
                <div className="u-mb-24">
                  <Typography.Heading
                    extendClasses="color-deepGreen"
                    textStyle="center"
                    fontweight="500"
                  >
                    Hệ thống tiếp nhận báo cáo!
                  </Typography.Heading>
                </div>
                <Controller
                  name="reportedReason"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextArea
                        {...field}
                        rows={12}
                        extendClasses={mapModifiers(
                          'p-scanCode_textarea',
                          error && 'error'
                        )}
                        status={error ? 'error' : undefined}
                        placeholder="Nhập lý do"
                      />
                      {error && (
                        <div className="p-scanCode_error">{error.message}</div>
                      )}
                    </>
                  )}
                />
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </MainLayout>
  );
};

export default PageReport;
