import DOMPurify from 'dompurify';
import React from 'react';
import { Button, Typography } from 'tfc-components';

import CustomModal from 'components/organisms/Modal';

interface NotifyModalProps {
  isOpen: boolean;
  title?: string,
  desc?: string,
  submitText?: string,
  backText?: string,
  handleSubmit?: () => void;
  handleBack?: () => void;
  loadingSubmit?: boolean;
  isError?: boolean;
}

const NotifyModal: React.FC<NotifyModalProps> = ({
  isOpen,
  title,
  desc,
  submitText,
  backText,
  handleSubmit,
  handleBack,
  loadingSubmit,
  isError,
}) => (
  <div className="t-notifyModal">
    <CustomModal
      showIconClose
      isOpen={isOpen}
      modifiers={['width-336']}
    >
      {
        title && (
          <Typography.Text
            textStyle="center"
            fontweight="700"
            extendClasses="fs-20 color-smokyBlack"
          >
            {title}
          </Typography.Text>
        )
      }
      {
        desc && (
          <div className="t-notifyModal_desc">
            <Typography.Text
              type="p"
              textStyle="center"
              fontweight="500"
              extendClasses="fs-14 color-smokyBlack"
            >
              <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc) }} />
            </Typography.Text>
          </div>
        )
      }
      <div className="t-notifyModal_button">
        {
          backText && (
            <div className="t-notifyModal_button-back" onClick={handleBack}>
              <Button
                variant="primary"
                primaryColor="#015714"
                hoveredPrimaryColor="#186e2b"
                type="submit"
              >
                <Typography.Text
                  fontweight="600"
                >
                  {backText.toUpperCase()}
                </Typography.Text>
              </Button>
            </div>
          )
        }
        {
          submitText && (
            <div className="t-notifyModal_button-submit" onClick={handleSubmit}>
              <Button
                variant={isError ? 'danger' : 'primary'}
                primaryColor="#015714"
                hoveredPrimaryColor="#186e2b"
                type="submit"
                loading={loadingSubmit}
              >
                <Typography.Text
                  fontweight="600"
                  extendClasses="color-white"
                >
                  {submitText.toUpperCase()}
                </Typography.Text>
              </Button>
            </div>
          )
        }
      </div>
    </CustomModal>
  </div>
);

export default NotifyModal;
