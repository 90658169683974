import { useNavigate } from 'react-router-dom';

import useDidMount from './useDidMount';

import { getAccessToken } from 'services/common/storage';
import { useAppDispatch } from 'store/hooks';
import { getProfileAsync } from 'store/provinceLeader';

const useInitializeRender = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useDidMount(() => {
    const token = getAccessToken();
    if (token) {
      dispatch(getProfileAsync()).unwrap().catch(() => {
        navigate('/login');
      });
    } else {
      navigate('/login');
    }
  });
};

export default useInitializeRender;
