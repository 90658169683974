import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { removeAccessToken, removeRefreshAccessToken } from 'services/common/storage';
import { ProvinceLeaderResource } from 'services/models';
import { getProfileService } from 'services/provinceLeader';

interface StateProps {
  profile: ProvinceLeaderResource | null;
  isLoading: boolean
}

const initialState: StateProps = {
  profile: null,
  isLoading: false
};

export const getProfileAsync = createAsyncThunk<ProvinceLeaderResource>(
  'provinceLeaderReducer/getProfile',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getProfileService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const provinceLeaderSlice = createSlice({
  name: 'provinceLeaderReducer',
  initialState,
  reducers: {
    logout: ($state) => {
      removeAccessToken();
      removeRefreshAccessToken();
      $state.profile = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(getProfileAsync.pending, ($state) => {
      $state.isLoading = true;
    });
    builder.addCase(getProfileAsync.fulfilled, (
      $state,
      action
    ) => {
      $state.isLoading = false;
      $state.profile = action.payload;
    });
    builder.addCase(getProfileAsync.rejected, ($state) => {
      $state.isLoading = false;
    });
  }
});

export const { logout } = provinceLeaderSlice.actions;

export default provinceLeaderSlice.reducer;
