import React from 'react';
import { useMutation } from 'react-query';
import { Button, Loading, Typography } from 'tfc-components';

import Icon from 'components/atoms/Icon';
import CustomModal, { ModalProps } from 'components/organisms/Modal';
import { exchangePrizeService } from 'services/prizes';

interface InforProps {
  id: number;
  giftCode: string,
  giftType: number,
  name: string,
  phone: string,
  province: string,
}

interface ConfirmModalProps extends ModalProps {
  listData: InforProps,
  handleCheck: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  handleClose,
  handleCheck,
  listData
}) => {
  /* State */
  const [error, setError] = React.useState<string>('');
  // const [saleId, setSaleId] = React.useState<number>();
  // const [saleSup, setSaleSup] = React.useState(false);

  /* Queries */
  // const {
  //   data,
  //   fetchNextPage,
  // } = useInfiniteQuery(
  //   ['getListSale'],
  //   async ({ pageParam = 1 }) => getListSaleService(
  //     { page: pageParam, limit: 15, provinceCode: listData.province }
  //   ),
  //   {
  //     getPreviousPageParam: (firstPage) => firstPage.meta.page - 1 ?? undefined,
  //     getNextPageParam: (lastPage) => lastPage.meta.page + 1 ?? undefined,
  //   },
  // );

  const { mutate: exchangeMutate, isLoading } = useMutation(
    ['exchangePrizeMutate'],
    exchangePrizeService,
    {
      onSuccess: () => {
        handleCheck();
      },
      onError(err: any) {
        if (err.length > 0 && err[0].field === 'code' && err[0].code === 'validate_003') {
          setError('Mã thẻ cào không tồn tại.');
        }
        if (err?.response?.status === 404) {
          setError('Mã thẻ cào không hợp lệ.');
        } else {
          setError('Đã có lỗi xảy ra!');
        }
      },
    }
  );

  return (
    <CustomModal
      showIconClose
      isOpen={isOpen}
      variant="scanCode"
    >
      <div className="confirmModal_wrapper">
        <div className="confirmModal_confirmWrapper">
          <div className="confirmModal_checkedIcon">
            <Icon iconName="greenChecked" size="52" />
          </div>
          <div className="confirmModal_confirmText">
            <Typography.Text
              textStyle="center"
              extendClasses="color-deepGreen"
            >
              Xác nhận
            </Typography.Text>
          </div>
        </div>
        <div className="confirmModal_content">
          <div className="confirmModal_item confirmModal_firstItem">
            <Typography.Text>
              Mã không được cào
            </Typography.Text>
            <Typography.Text
              fontweight="500"
              extendClasses="fs-14 color-raisinBlack"
              textStyle="right"
            >
              {listData.giftCode}
            </Typography.Text>
          </div>
          <div className="confirmModal_item">
            <Typography.Text>
              Loại quà
            </Typography.Text>
            <Typography.Text
              fontweight="500"
              extendClasses="fs-14 color-raisinBlack"
              textStyle="right"
            >
              {listData.giftType === 1 ? '2 chỉ vàng' : '1,000,000 VND'}
            </Typography.Text>
          </div>
          <div className="confirmModal_item">
            <Typography.Text>
              Tên khách hàng
            </Typography.Text>
            <Typography.Text
              fontweight="500"
              extendClasses="fs-14 color-raisinBlack"
              textStyle="right"
            >
              {listData.name}
            </Typography.Text>
          </div>
          <div className="confirmModal_item">
            <Typography.Text>
              Số điện thoại
            </Typography.Text>
            <Typography.Text
              fontweight="500"
              extendClasses="fs-14 color-raisinBlack"
              textStyle="right"
            >
              {listData.phone}
            </Typography.Text>
          </div>
          {/* <div>
            <div className="confirmModal_item">
              <Typography.Text>
                Tên sale SUP
              </Typography.Text>
              <Pulldown
                placeholder="Chọn tên sale SUP"
                optionsData={data ? data.pages.reduce((prev: OptionType[], curr) => (
                  [...prev, ...curr.data.map(((sale) => (
                    { label: sale.saleData.name, value: sale.saleData.id })))]), []) : []}
                handleLoadMore={fetchNextPage}
                handleChange={(option) => {
                  setSaleId(Number(option.value));
                  setSaleSup(false);
                }}
              />
            </div>
            {saleSup && (
              <div className="p-scanCode_notify">Vui lòng chọn Saler</div>
            )}
          </div> */}
          {error && (
            <div className="p-scanCode_error">{error}</div>
          )}
        </div>
        <div className="confirmModal_buttonGroup">
          <Button
            variant="primary"
            primaryColor="#fff"
            extendClasses="confirmModal_cancelButton"
            hoveredPrimaryColor="#fff"
            onClick={handleClose}
          >
            <Typography.Text
              fontweight="500"
              extendClasses="fs-14 color-raisinBlack"
            >
              HỦY BỎ
            </Typography.Text>
          </Button>
          <Button
            variant="primary"
            primaryColor="#015714"
            hoveredPrimaryColor="#186e2b"
            extendClasses="confirmModal_checkButton"
            loading={isLoading}
            loadingIndicator={<Loading.CircleDashed width={24} color="#fff" />}
            onClick={() => {
              exchangeMutate({ id: listData.id, code: listData.giftCode });
            }}
          >
            <Typography.Text
              fontweight="500"
              extendClasses="fs-14"
            >
              XÁC NHẬN
            </Typography.Text>
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmModal;
