import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller, FormProvider, useForm, useWatch
} from 'react-hook-form';
import { useInView } from 'react-intersection-observer';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { Input, Loading } from 'tfc-components';

import Pulldown from 'components/molecules/Pulldown';
import PrizeItem from 'components/organisms/PrizeItem';
import MainLayout from 'components/templates/MainLayout';
import useDebounce from 'hooks/useDebounce';
import { PrizeExchangeCollectionResource } from 'services/models';
import { getPrizeExchangesService } from 'services/prizes';
import { useAppSelector } from 'store/hooks';
import { ROUTE_PATHS } from 'utils/constants';
import mapModifiers, { formatDateTime } from 'utils/functions';

type FormTypes = {
  phone?: string;
  status?: OptionType;
  provinces?: OptionType;
};

const ExchangeList: React.FC = () => {
  const { prize } = useParams<{ prize: string }>();
  const { ref, inView } = useInView();
  const totalPageRef = React.useRef(1);
  const pageRef = React.useRef(1);
  const { profile } = useAppSelector((store) => store.provinceLeader);
  const [exchangeData, setExchangeData] = useState<PrizeExchangeCollectionResource[]>([]);
  const [phoneDebounce, setPhoneDebounce] = useState<string | undefined>();

  const method = useForm<FormTypes>({
    mode: 'onChange',
  });

  const phone = useWatch({ name: 'phone', control: method.control });
  const status = useWatch({ name: 'status', control: method.control });
  const provinces = useWatch({ name: 'provinces', control: method.control });

  useDebounce(() => {
    setPhoneDebounce(phone || undefined);
  }, 500, [phone]);

  const formatProvincesData: OptionType[] = useMemo(() => profile?.provinces?.map((item) => (
    {
      label: item.name || '',
      value: item.code
    }
  )) || [], [profile?.provinces]);

  const { mutate, isLoading } = useMutation(
    [`getPrizeExchanges-${prize}`, prize, phoneDebounce, status, provinces],
    () => getPrizeExchangesService({
      prize: prize === 'first' ? 1 : 2,
      page: pageRef.current,
      limit: 10,
      phone: phoneDebounce,
      documentAdded: status?.value,
      provinceCode: provinces?.value
    }),
    {
      onSuccess: (data) => {
        totalPageRef.current = data.meta.totalPages;
        if (data.meta.page > 1) {
          setExchangeData([...exchangeData, ...data.data]);
        } else {
          setExchangeData(data.data);
        }
        if (pageRef.current <= data.meta.totalPages) {
          pageRef.current = data.meta.page + 1;
        }
      },
    }
  );

  useEffect(() => {
    pageRef.current = 1;
    mutate();
  }, [mutate, phoneDebounce, status, provinces]);

  useEffect(() => {
    if (inView && pageRef.current <= totalPageRef.current) {
      mutate();
    }
  }, [mutate, inView]);

  return (
    <MainLayout title={`Danh sách ${prize === 'first' ? 'giải nhất' : 'giải nhì'}`}>
      <FormProvider {...method}>
        <Controller
          name="phone"
          render={({ field, fieldState: { error } }) => (
            <div style={{ width: '100%' }}>
              <Input
                {...field}
                extendClasses={mapModifiers('p-scanCode_input', error ? 'error' : '')}
                bordered
                placeholder="Số điện thoại"
              />
              {error && (
                <div className="p-scanCode_error">{error.message}</div>
              )}
            </div>
          )}
        />
        <div className="p-exchangeList_wrapPulldown">
          <div className="p-exchangeList_pulldown">
            <Controller
              name="status"
              render={({ field: { onChange } }) => (
                <Pulldown
                  placeholder="Tình trạng chứng từ"
                  optionsData={[
                    {
                      label: 'Tất cả',
                      value: undefined
                    },
                    {
                      label: 'Đã cập nhật chứng từ',
                      value: true
                    },
                    {
                      label: 'Chưa cập nhật chứng từ',
                      value: false
                    }
                  ]}
                  handleChange={onChange}
                />
              )}
            />
          </div>
          <div className="p-exchangeList_pulldown">
            <Controller
              name="provinces"
              render={({ field: { onChange } }) => (
                <Pulldown
                  placeholder="Tỉnh/Thành"
                  name="provinces"
                  optionsData={[{ label: 'Tất cả', value: '' }, ...formatProvincesData]}
                  handleChange={onChange}
                />
              )}
            />
          </div>
        </div>
      </FormProvider>
      {exchangeData.map((prizeItem) => (
        <div
          key={`exchangeList${prizeItem.prizeExchangeData?.id}`}
          className="p-exchangeList"
        >
          <PrizeItem
            name={prizeItem.prizeExchangeData?.name}
            phone={prizeItem.prizeExchangeData?.phone}
            contactedAt={formatDateTime(prizeItem.prizeExchangeData?.contactedAt)}
            slug={`${ROUTE_PATHS.CUSTOMER_INFO}?id=${prizeItem.prizeExchangeData?.id}`}
            reportedAt={formatDateTime(prizeItem.prizeExchangeData?.reportedAt)}
            reportedReason={prizeItem.prizeExchangeData?.reportedReason}
            documentAddedAt={formatDateTime(prizeItem.prizeExchangeData?.documentAddedAt)}
            provinces={prizeItem?.province?.name}
            rejectedAt={formatDateTime(prizeItem.prizeExchangeData?.documentRejectedAt)}
          />
        </div>
      ))}
      {isLoading && <div className="p-exchangeList_loading"><Loading.CircleDashed width={24} color="#015714" /></div>}
      <div className="p-exchangeList_loadMore" ref={ref} />
    </MainLayout>
  );
};

export default ExchangeList;
