import React, { useRef, useState } from 'react';
import { Link, Typography } from 'tfc-components';

import Icon from 'components/atoms/Icon';
import useClickOutside from 'hooks/useClickOutside';
import mapModifiers, { checkExternalUrl } from 'utils/functions';

interface PrizeItemProps {
  name?: string,
  phone?: string,
  slug?: string,
  reportedAt?: string;
  reportedReason?: string;
  target?: string,
  contactedAt?: string;
  documentAddedAt?: string;
  provinces?: string;
  rejectedAt?: string;
}

const PrizeItem: React.FC<PrizeItemProps> = ({
  name,
  phone,
  slug,
  target,
  reportedAt,
  reportedReason,
  contactedAt,
  documentAddedAt,
  provinces,
  rejectedAt
}) => {
  const [showReportedIcon, setShowReportedIcon] = useState(false);
  const [showRejectedIcon, setShowRejectedIcon] = useState(false);

  const reportedTooltipRef = useRef<HTMLDivElement>(null);
  const rejectedTooltipRef = useRef<HTMLDivElement>(null);

  useClickOutside(reportedTooltipRef, () => setShowReportedIcon(false));
  useClickOutside(rejectedTooltipRef, () => setShowRejectedIcon(false));

  return (
    <div className="o-prizeItem">
      <Link
        extendClasses="o-prizeItem_item"
        href={slug}
        useExternal={checkExternalUrl(slug || '')}
        target={target}
      >
        <div className="o-prizeItem_name">
          <Typography.Text
            fontweight="500"
            extendClasses="color-raisinBlack fs-14x20"
          >
            {name}
          </Typography.Text>
          <div className="u-mt-4">
            <Typography.Text
              extendClasses="color-raisinBlack fs-14x20"
            >
              {phone}
            </Typography.Text>
          </div>
          {provinces && (
            <div className="u-mt-4">
              <Typography.Text
                extendClasses="color-raisinBlack fs-14x20"
              >
                {`Tỉnh/Thành: ${provinces}`}
              </Typography.Text>
            </div>
          )}
          {contactedAt && (
            <div className="u-mt-4">
              <Typography.Text
                extendClasses="color-raisinBlack fs-14x20"
              >
                {`Liên lạc vào lúc: ${contactedAt}`}
              </Typography.Text>
            </div>
          )}
          {documentAddedAt && (
            <div className="u-mt-4">
              <Typography.Text
                extendClasses="color-raisinBlack fs-14x20"
              >
                {`Cập nhật chứng từ lúc: ${documentAddedAt}`}
              </Typography.Text>
            </div>
          )}
        </div>
        {
          reportedAt ? (
            <div className="m-exchangeStatus_wrapIcon">
              <Icon iconName="arrowNext" size="24" />
            </div>
          ) : null
        }
        <div className="o-prizeItem_iconWrapper">
          {rejectedAt && (
            <div ref={rejectedTooltipRef} className="o-prizeItem_rejectedIcon">
              <div className="o-prizeItem_tooltip">
                <div className={mapModifiers('o-prizeItem_tooltip_content', showRejectedIcon && 'show')}>
                  <Typography.Text>
                    Chứng từ bị từ chối lúc
                    <br />
                    {rejectedAt}
                  </Typography.Text>
                </div>
                <div
                  className="o-prizeItem_tooltip_children"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowRejectedIcon(!showRejectedIcon);
                  }}
                >
                  <Icon iconName="reject" size="24" />
                </div>
              </div>
            </div>
          )}
          {reportedAt && (
            <div ref={reportedTooltipRef} className="o-prizeItem_reportedIcon">
              <div className="o-prizeItem_tooltip">
                <div className={mapModifiers('o-prizeItem_tooltip_content', showReportedIcon && 'show')}>
                  <Typography.Text>
                    {reportedReason}
                    <br />
                    {reportedAt}
                  </Typography.Text>
                </div>
                <div
                  className="o-prizeItem_tooltip_children"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowReportedIcon(!showReportedIcon);
                  }}
                >
                  <Icon iconName="error" size="24" />
                </div>
              </div>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default PrizeItem;
