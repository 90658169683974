import './App.scss';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loading } from 'tfc-components';

import useInitializeRender from 'hooks/useInitializeRender';
import CustomerInfo from 'pages/CustomerInfo';
import DocumentDetail from 'pages/DocumentDetail';
import DocumentList from 'pages/DocumentList';
import ExchangeList from 'pages/ExchangeList';
import Home from 'pages/Home';
import Login from 'pages/Login';
import PageReport from 'pages/PageReport';
import ScanCode from 'pages/ScanCode';
import SuccessfulScanning from 'pages/ScanCode/successfull';
import { store } from 'store';
import { useAppSelector } from 'store/hooks';
import { ROUTE_PATHS } from 'utils/constants';

const routes = [
  {
    path: ROUTE_PATHS.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTE_PATHS.HOME,
    element: <Home />,
  },
  {
    path: ROUTE_PATHS.SCAN_CODE,
    element: <ScanCode />
  },
  {
    path: ROUTE_PATHS.CUSTOMER_INFO,
    element: <CustomerInfo />
  },
  {
    path: `${ROUTE_PATHS.EXCHANGE_LIST}/:prize`,
    element: <ExchangeList />
  },
  {
    path: ROUTE_PATHS.SUCCESSFULL,
    element: <SuccessfulScanning />
  },
  {
    path: ROUTE_PATHS.DOCUMENT_LIST,
    element: <DocumentList />
  },
  {
    path: ROUTE_PATHS.REPORT,
    element: <PageReport />
  },
  {
    path: ROUTE_PATHS.DOCUMENT_DETAIL,
    element: <DocumentDetail />
  },
];

const App: React.FC = () => {
  const loading = useAppSelector((state) => state.provinceLeader.isLoading);
  useInitializeRender();
  if (loading) {
    return <Loading.Circle extendClasses="loading-main" color="#015714" width={54} />;
  }
  return (
    <Routes>
      {routes.map((route) => <Route key={route.path} {...route} />)}
    </Routes>
  );
};
const AppProvider: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppProvider;
