function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo(
      { behavior: 'smooth', top: ref.current.offsetTop - 68 },
    ); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}

export const checkExternalUrl = (str: string) => {
  const tareaRegex = /^(http|https)/;
  return tareaRegex.test(String(str).toLowerCase());
};

export const formatTimeHHMMSS = (date?: string) => {
  if (!date) {
    return '';
  }
  const formatDate = new Date(date);
  let hours: string | number = formatDate.getHours();
  let minute: string | number = formatDate.getMinutes();
  let second: string | number = formatDate.getSeconds();
  if (hours < 10) {
    hours = `0${hours}`;
  }

  if (minute < 10) {
    minute = `0${minute}`;
  }

  if (second < 10) {
    second = `0${second}`;
  }

  return `${hours}:${minute}:${second}`;
};

export const formatDate = (date?: string | Date, format?: 'DDMMYYYY' | 'YYYYMMDD') => {
  if (!date) return '';
  const dateFormat = typeof (date) === 'string' ? new Date(date) : date;
  let day: string | number = dateFormat.getDate();
  let month: string | number = dateFormat.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  switch (format) {
    case 'YYYYMMDD':
      return `${dateFormat.getFullYear()}-${month}-${day}`;
    default:
      return `${day}-${month}-${dateFormat.getFullYear()}`;
  }
};

export const formatDateTime = (time?: string): string | undefined => {
  if (!time) return undefined;

  const dateData = new Date(time);
  const hours = dateData.getHours() < 10 ? `0${dateData.getHours()}` : dateData.getHours();
  const minutes = dateData.getMinutes() < 10 ? `0${dateData.getMinutes()}` : dateData.getMinutes();
  const date = dateData.getDate() < 10 ? `0${dateData.getDate()}` : dateData.getDate();
  const months = dateData.getMonth() + 1 < 10
    ? `0${dateData.getMonth() + 1}` : dateData.getMonth() + 1;
  const year = dateData.getFullYear().toString();

  return `${date}/${months}/${year} ${hours}:${minutes}`;
};
