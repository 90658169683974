import * as yup from 'yup';

export const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const useSchemas = () => {
  const scanner = yup.object().shape({
    scanner: yup.string().required('Vui lòng nhập mã'),
  });

  const login = yup.object().shape({
    username: yup.string().required('Vui lòng nhập thông tin'),
    password: yup.string().required('Vui lòng nhập thông tin')
  });

  const customerInfo = yup.object().shape({
    name: yup.string().required('Vui lòng nhập thông tin'),
    address: yup.string().required('Vui lòng nhập thông tin'),
    phone: yup.string().required('Vui lòng nhập thông tin').matches(phoneRegExp, 'Chưa đúng định dạng'),
  });

  const documentDetail = yup.object().shape({
    beforeIdentityCard: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string().required('Cập nhật hình ảnh'),
    }),
    afterIdentityCard: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string().required('Cập nhật hình ảnh'),
    }),
    card: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string().required('Cập nhật hình ảnh'),
    }),
    awardMinutesOne: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string(),
    }).nullable(),
    awardMinutesTwo: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string(),
    }).nullable(),
    awardMinutesThird: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string(),
    }).nullable(),
    awardMinutesFourth: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string(),
    }).nullable(),
    awardPicture: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string().required('Cập nhật hình ảnh'),
    }),
    saleId: yup.object().shape({
      label: yup.string().required('Vui lòng chọn một Saler'),
      value: yup.number().required('Vui lòng chọn một Saler')
    }),
    actualAwardDate: yup.date().required('Vui lòng chọn ngày trao giải thực tế')
  });

  const documentDetailWithAwardFourth = yup.object().shape({
    beforeIdentityCard: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string().required('Cập nhật hình ảnh'),
    }),
    afterIdentityCard: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string().required('Cập nhật hình ảnh'),
    }),
    card: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string().required('Cập nhật hình ảnh'),
    }),
    awardMinutesOne: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string(),
    }),
    awardMinutesTwo: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string(),
    }),
    awardMinutesThird: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string(),
    }),
    awardMinutesFourth: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string(),
    }),
    awardPicture: yup.object().shape({
      file: yup.lazy((value) => (typeof value === 'string' ? yup.string() : yup.mixed<File>())),
      pathSubmit: yup.string().required('Cập nhật hình ảnh'),
    }),
    saleId: yup.object().shape({
      label: yup.string().required('Vui lòng chọn một Saler'),
      value: yup.number().required('Vui lòng chọn một Saler')
    })
  });

  const reportPrize = yup.object().shape({
    reportedReason: yup.string().required('Vui lòng nhập lý do'),
  });
  return {
    login,
    scanner,
    customerInfo,
    documentDetail,
    documentDetailWithAwardFourth,
    reportPrize,
  };
};
export default useSchemas;
